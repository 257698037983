<template>
    <div
        class="modal fade"
        id="kt_modal_assign_social_media_type"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered">
            <!--begin::Modal content-->
            <div class="modal-content p-5">
                <!--begin::Modal header-->
                <div class="modal-header bg-white p-0 pb-5 mb-5">
                    <!--begin::Heading-->
                    <h2>{{ __('Choose the right type') }}</h2>
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body p-0 m-0">
                    <div>
                        <p>
                            {{
                                __(
                                    "The content type you've created isn't suited for the content calendar. Please choose an appropriate post format to ensure it can be shared and added to the calendar."
                                )
                            }}
                        </p>
                        <div class="mb-5">
                            <label class="form-label" for="socialMediaType">
                                {{ __('Type') }}
                            </label>
                            <select
                                id="socialMediaType"
                                class="form-select"
                                v-model="
                                    contentCalendarStore.contentCalendar
                                        .social_media
                                "
                            >
                                <option
                                    v-for="socialMedia in contentCalendarStore.socialMedias"
                                    :key="socialMedia.value"
                                    :value="socialMedia.value"
                                >
                                    {{ __(socialMedia.name) }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button
                            class="btn btn-secondary me-3"
                            @click="closeModal"
                        >
                            {{ __('Cancel') }}
                        </button>
                        <button
                            class="btn btn-primary"
                            :class="{
                                disabled:
                                    !contentCalendarStore.contentCalendar
                                        .social_media,
                            }"
                            @click="next"
                        >
                            {{ __('Next') }}
                        </button>
                    </div>
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useContentCalendarStore } from '@/Stores/ContentCalendarStore';
import { Modal } from 'bootstrap';

export default defineComponent({
    setup() {
        const contentCalendarStore = useContentCalendarStore();

        return {
            contentCalendarStore,
        };
    },
    data() {
        return {
            modal: {},
            saveProgress: false,
        };
    },
    mounted() {
        let vm = this;

        this.modal = new Modal(
            document.getElementById('kt_modal_assign_social_media_type')
        );

        // Add event listener for modal hidden event
        document
            .getElementById('kt_modal_assign_social_media_type')
            .addEventListener('hidden.bs.modal', this.handleModalHidden);

        // eslint-disable-next-line no-undef
        emitter.on('open-assign-social-media-type-modal', () => {
            console.log({'#socialtype': this.contentCalendarStore.contentCalendar.social_media});
            if (this.contentCalendarStore.contentCalendar.social_media) {
                this.next();
                return;
            }
            return vm.modal.show();
        });
        // eslint-disable-next-line no-undef
        emitter.on('close-assign-social-media-type-modal', () =>
            vm.modal.hide()
        );
    },
    beforeUnmount() {
        // Remove the event listener when component is unmounted
        document
            .getElementById('kt_modal_assign_social_media_type')
            .removeEventListener('hidden.bs.modal', this.handleModalHidden);

        // eslint-disable-next-line no-undef
        emitter.off('open-assign-social-media-type-modal');
        // eslint-disable-next-line no-undef
        emitter.off('close-assign-social-media-type-modal');
    },
    methods: {
        handleModalHidden() {
            // This method will be called whenever the modal is hidden
            if (!this.saveProgress) {
                this.contentCalendarStore.$reset();
            }
            // Reset the flag
            this.saveProgress = false;
        },
        closeModal() {
            this.saveProgress = true;
            this.modal.hide();
        },
        next() {
            this.saveProgress = true;
            this.contentCalendarStore.contentCalendar.status = 'pending';
            this.modal.hide();
            // eslint-disable-next-line no-undef
            emitter.emit('open-content-calendar-content-preview-modal');
        },
    },
});
</script>

<style lang="scss">
#kt_modal_assign_social_media_type {
    .modal-content {
        .modal-header {
            z-index: 1;
        }
    }
}
</style>

<template>
    <div class="chat-action mx-auto mw-800px">
        <!--begin:Toolbar-->
        <div class="flex items-center bg-white border rounded-2 p-1.5 relative">
            <div class="d-flex align-items-center me-2"></div>
            <button
                v-if="chatUuID && !responseLoading"
                class="regenerate-btn bg-gray-200 text-sm absolute"
                @click="regenerateResponse"
            >
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                    <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo7/dist/../src/media/svg/icons/General/Update.svg--><svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                    >
                        <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                                d="M8.43296491,7.17429118 L9.40782327,7.85689436 C9.49616631,7.91875282 9.56214077,8.00751728 9.5959027,8.10994332 C9.68235021,8.37220548 9.53982427,8.65489052 9.27756211,8.74133803 L5.89079566,9.85769242 C5.84469033,9.87288977 5.79661753,9.8812917 5.74809064,9.88263369 C5.4720538,9.8902674 5.24209339,9.67268366 5.23445968,9.39664682 L5.13610134,5.83998177 C5.13313425,5.73269078 5.16477113,5.62729274 5.22633424,5.53937151 C5.384723,5.31316892 5.69649589,5.25819495 5.92269848,5.4165837 L6.72910242,5.98123382 C8.16546398,4.72182424 10.0239806,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 L6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C10.6885336,6 9.44767246,6.42282109 8.43296491,7.17429118 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                            />
                        </g>
                    </svg>
                    <!--end::Svg Icon-->
                </span>
                {{ __('Regenerate response') }}
            </button>
            <!--begin Send-->
            <div class="d-flex flex-1">
                <div class="d-flex flex-1">
                    <!--begin Input-->
                    <textarea
                        class="form-control form-control-flush p-0 text-base"
                        rows="1"
                        data-kt-element="input"
                        :placeholder="__('How can I help you?')"
                        v-model="question"
                        @keypress="keyPressed"
                        @input="adjustTextareaHeight"
                        ref="textareaInput"
                    ></textarea>
                    <!--end Input-->
                </div>
                <div class="d-flex align-items-end">
                    <button
                        v-if="!isStreaming"
                        class="btn btn-primary text-sm"
                        :class="[isStreaming ? 'disabled' : '']"
                        type="button"
                        @click="fetchAnswer"
                        :disabled="isSendButtonDisabled"
                    >
                        {{ __('Send') }}
                    </button>
                    <button
                        v-else
                        class="btn btn-danger text-sm"
                        type="button"
                        @click="stopStreaming"
                    >
                        {{ __('Stop') }}
                    </button>
                </div>
            </div>
            <!--end Send-->
        </div>
        <!--end Toolbar-->
    </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import trialLimitExhausted from '@/Mixins/trialLimitExhausted';

export default {
    mixins: [trialLimitExhausted],
    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { toast };
    },

    props: {
        user: Object,
        chatUuID: String,
        page: Object,
        exampleChat: {
            type: String,
            default: null,
        },
        previousQuestion: {
            type: String,
            default: null,
        },
        isStreaming: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            question: '',
            responseLoading: false,
            error: false,
            isSendButtonDisabled: true,
            abortController: null,
        };
    },

    mounted() {
        if (this.$page.component === 'AiChat/AiChat') {
            this.setUserQueryIfExists();
        }
    },

    watch: {
        exampleChat(newValue) {
            this.question = newValue;
        },
        question(newValue) {
            this.adjustTextareaHeight();
            this.isSendButtonDisabled = newValue.length > 32764;
        },
    },
    methods: {
        setUserQueryIfExists() {
            const urlParms = new URLSearchParams(window.location.search);
            const query = urlParms.get('query');
            if (query) {
                this.question = query;
                this.fetchAnswer();
            }
        },
        keyPressed(event) {
            if (13 === event.keyCode && !event.shiftKey) {
                event.preventDefault();
                this.fetchAnswer();
            }
        },

        adjustTextareaHeight() {
            this.$nextTick(() => {
                const textarea = this.$refs.textareaInput;
                if (textarea) {
                    textarea.style.height = 'auto';
                    const newHeight = Math.min(textarea.scrollHeight, 200);
                    textarea.style.height = `${newHeight}px`;
                }
            });
        },

        fetchAnswer(regenerateLastChat = 0) {
            if (!this.chatUuID) {
                this.createChatAndSendMessage();
            } else {
                this.sendMessage();
            }
        },

        createChatAndSendMessage() {
            if (
                !(this.userTeamHasEnoughCredits || this.userHasUnlimitedUsage)
            ) {
                this.toast.error(this.__('Your credits are over'));
                this.showTrialLimitExhaustedPopUp();
                return false;
            }
            // Request to create a new chat
            // eslint-disable-next-line no-undef
            axios
                .post('/api/chat-gpt-conversation', {})
                .then((response) => {
                    this.$emit('update-chat-uuid', response.data.data.uuid);
                    this.$nextTick(() => {
                        let promptForChatName = this.question;
                        this.sendMessage();
                        this.generateChatName(promptForChatName);
                    });
                })
                .catch((error) => {
                    console.error('Error creating new chat', error);
                    // Handle error
                });
        },

        generateChatName(prompt) {
            let requestUrl = `/api/chat-gpt-conversation/generate-chat-name`;
            let requestParams = {
                prompt: prompt,
                chat_uuid: this.chatUuID,
            };
            const vm = this;

            // eslint-disable-next-line no-undef
            axios
                .post(requestUrl, requestParams)
                .then(() => {
                    this.$nextTick(() => {
                        // eslint-disable-next-line no-undef
                        emitter.emit('reload-chat-list');
                    });
                })
                .catch((error) => {
                    console.error('Error generating chat name', error);
                    vm.toast.error(error.response.data.message);
                    if (
                        error.response.data.error_code ==
                        'trial_credit_limit_exhausted'
                    ) {
                        vm.showTrialLimitExhaustedPopUp();
                    }
                });
        },

        async sendMessage() {
            let vm = this;

            if (!this.question) {
                return false;
            }
            if (
                !(this.userTeamHasEnoughCredits || this.userHasUnlimitedUsage)
            ) {
                this.toast.error(this.__('Your credits are over'));
                this.showTrialLimitExhaustedPopUp();
                return false;
            }

            this.responseLoading = true;
            this.$emit('streaming-started');

            try {
                this.$emit('send-button-triggered', {
                    question: this.question,
                });

                if (!this.csrfToken) {
                    this.toast.error(this.__('Token mismatch'));
                }

                let requestParams = {
                    prompt: this.question,
                    chat_uuid: this.chatUuID,
                };

                this.question = '';

                this.abortController = new AbortController();

                // eslint-disable-next-line no-undef
                const response = await fetch(route('chat-start'), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': this.csrfToken,
                    },
                    body: JSON.stringify(requestParams),
                    signal: this.abortController.signal,
                });

                const reader = response.body?.getReader();
                const decoder = new TextDecoder('utf-8');

                if (!reader) return;

                // eslint-disable-next-line no-constant-condition
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
                    const chunk = decoder.decode(value, { stream: true });

                    // Parse and handle SSE
                    chunk.split('\n').forEach((line) => {
                        if (line.startsWith('data:')) {
                            try {
                                const data = JSON.parse(
                                    line.replace('data: ', '')
                                );
                                this.$emit('on-streaming', data);
                            } catch (error) {
                                console.error('EventSource failed:', error);
                                this.$emit(
                                    'got-chat-response-error',
                                    error.name
                                );
                            }
                        }
                    });
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    console.error('Error:', error);
                }
            } finally {
                if (!vm.error) {
                    vm.question = '';
                }
                vm.error = false;
                vm.responseLoading = false;
                vm.$emit('streaming-stopped');
                this.abortController = null;
            }
        },

        async regenerateResponse() {
            if (this.chatUuID) {
                await this.$emit('chat-regenerate-response');
                if (this.previousQuestion) {
                    this.question = this.previousQuestion;
                    this.fetchAnswer(1);
                }
            }
        },

        stopStreaming() {
            if (this.abortController) {
                this.abortController.abort();
                this.responseLoading = false;
                this.$emit('streaming-stopped');
            }

            if (this.chatUuID) {
                axios
                    .post(route('chat-stop'), {
                        chat_uuid: this.chatUuID,
                    })
                    .then(() => {
                        console.log('Streaming stopped');
                    })
                    .catch((error) => {
                        console.error('Error stopping streaming', error);
                        this.toast.error(this.__('Failed to stop streaming'));
                    });
            }
        },
    },
    computed: {
        csrfToken() {
            try {
                return this.page.props.csrf_token;
            } catch (error) {
                console.error('CSRF token issue in file upload');
            }
            return '';
        },
        userTeamHasEnoughCredits() {
            return (
                this.user.current_team.team_plan_credits?.character_credits >
                    0 ||
                this.user.current_team.team_bonus_credits.character_credits > 0
            );
        },

        userHasUnlimitedUsage() {
            let user = this.user;
            let plans = this.getPlans();

            // Check if the user has a normal standard or professional subscription
            let hasNormalSubscription = user.current_team.subscriptions.some(
                (subscription) => {
                    const paddlePlanId = subscription.paddle_plan;
                    const professionalPlans = plans.professional;
                    const standardPlans = plans.standard;

                    return (
                        professionalPlans.includes(paddlePlanId) ||
                        standardPlans.includes(paddlePlanId)
                    );
                }
            );

            // Check if the user has a special lifetime subscription based on plan name or having more than one lifetime subscription
            let hasLifetimeSubscription =
                user.current_team.lifetime_subscriptions.some(
                    (subscription) => {
                        // Check if the plan name is "professional" or "standard"
                        if (
                            subscription.plan &&
                            ['professional', 'standard'].includes(
                                subscription.plan.toLowerCase()
                            )
                        ) {
                            return true;
                        } else if (
                            subscription.plan &&
                            ['basic'].includes(subscription.plan.toLowerCase())
                        ) {
                            // Check if the plan name is "basic"
                            return false;
                        }

                        // Check if the user has more than one lifetime subscription
                        return (
                            user.current_team.lifetime_subscriptions.length > 0
                        );
                    }
                );

            // Return true if the user has a normal subscription or a special lifetime subscription
            return hasNormalSubscription || hasLifetimeSubscription;
        },

        planBasedLtd() {
            return (
                this.user.current_team.lifetime_subscriptions.filter(
                    (subscription) => {
                        return subscription.plan;
                    }
                ).length > 0
            );
        },
    },
    beforeDestroy() {
        if (this.abortController) {
            this.abortController.abort();
        }
    },
};
</script>

<style lang="scss">
.chat-action {
    .btn {
        margin-left: 15px;
    }
}

.regenerate-btn {
    display: flex;
    align-items: center;
    white-space: nowrap;
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    top: -40px;
    left: 42%;
    color: #000;
    animation: fadeinout 4s;
    animation-iteration-count: 1;

    @media (max-width: 767px) {
        left: auto;
        top: -45px;
        right: 16px;

        span {
            margin-right: 0 !important;
        }
    }

    span {
        color: #000;
    }
}

@keyframes fadeinout {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.regenerate-btn {
    color: #000;

    @media (max-width: 767px) {
        padding: 5px !important;
        font-size: 0;
    }

    &:hover,
    &:focus {
        color: #000;
    }

    span {
        color: #000;
    }
}
</style>

import MarkdownIt from 'markdown-it';

export default {
    methods: {
        renderPlainText(markdown, settings = {}) {
            const defaultSettings = {
                decodeUnicodeSequences: true,
                removeSurroundingQuotes: true,
            };
            settings = {
                ...defaultSettings,
                ...settings,
            };
            // Initialize MarkdownIt
            const md = new MarkdownIt();
            // Render the markdown to HTML
            const renderedHtml = md.render(markdown);
            // Create a temporary DOM element to strip HTML tags
            const tempElement = document.createElement('div');
            tempElement.innerHTML = renderedHtml;
            // Extract plain text
            let plainText =
                tempElement.textContent || tempElement.innerText || '';
            if (settings.decodeUnicodeSequences) {
                // Decode Unicode escape sequences
                plainText = decodeUnicodeSequences(plainText);
            }
            if (settings.removeSurroundingQuotes) {
                // Remove surrounding quotes if present
                plainText = removeSurroundingQuotes(plainText);
            }
            // Replace escaped line breaks with actual newlines
            plainText = plainText.replace(/\\n/g, '\n');
            // Remove markdown header symbols (#) only at the beginning of a line
            plainText = plainText.replace(/^\s*#+\s+/gm, ''); // Target markdown headers
            return plainText;
        },
    },
};

// Private utility functions
function decodeUnicodeSequences(text) {
    // Replace all Unicode escape sequences like \uXXXX with their respective characters
    return text.replace(/\\u([\dA-F]{4})/gi, (match, grp) => {
        return String.fromCharCode(parseInt(grp, 16));
    });
}

function removeSurroundingQuotes(text) {
    // To remove \n from the end
    while (text.endsWith('\n')) {
        text = text.slice(0, -1);
    }

    // Check if the string starts and ends with double quotes
    if (text.startsWith('"') && text.endsWith('"')) {
        // Remove the quotes
        return text.slice(1, -1);
    }

    return text;
}
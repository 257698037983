<template>
    <div class="d-flex flex-column flex-lg-row-fluid py-10">
        <!--begin::Content-->
        <div class="d-flex flex-column flex-column-fluid">
            <!--begin::Wrapper-->
            <div class="px-10 px-lg-15 h-100">
                <!--begin::Title-->
                <h2 class="fw-bolder d-flex align-items-center mb-10 text-dark">
                    {{ resultTitle }}
                </h2>
                <!--end::Title-->

                <div v-show="!loading">
                    <div
                        v-for="(item, index) in choices"
                        class="col-md-12 h-100"
                    >
                        <div
                            class="border rounded position-relative p-0 d-flex flex-column align-items-stretch justify-content-between mb-5"
                        >
                            <div
                                class="d-flex border-bottom justify-content-between top-0 end-0 bg-lighten"
                            >
                                <div class="actions">
                                    <a
                                        href="#"
                                        class="btn btn-icon btn-hover-scale rounded-0 border-end btn-active-color-primary btn-sm"
                                        :class="
                                            generatingContent
                                                ? 'cursor-not-allowed'
                                                : 'cursor-pointer'
                                        "
                                        @click.prevent="saveToCalendar(index)"
                                    >
                                        <span
                                            class="svg-icon svg-icon-muted svg-icon-3 calendar-dot position-relative"
                                        >
                                            <i class="bi bi-calendar fs-5"></i>
                                        </span>
                                    </a>
                                    <a
                                        v-if="inModal"
                                        href="#"
                                        class="btn btn-icon btn-hover-scale rounded-0 border-end btn-active-color-primary btn-sm"
                                        @click.prevent="onInsert(index)"
                                    >
                                        <span
                                            class="svg-icon svg-icon-muted svg-icon-3"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                            >
                                                <path
                                                    d="M416 128h-40C362.7 128 352 138.7 352 152c0 13.25 10.75 24 24 24H416c8.836 0 16 7.164 16 16v256c0 8.836-7.164 16-16 16H96c-8.836 0-16-7.164-16-16V192c0-8.836 7.164-16 16-16h40C149.3 176 160 165.3 160 152C160 138.7 149.3 128 136 128H96C60.65 128 32 156.7 32 192v256c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V192C480 156.7 451.3 128 416 128zM143.7 238.6C133.1 247.6 133.4 262.8 142.4 272.6l96 104.1c9.062 9.82 26.19 9.82 35.25 0l96-104.1c9-9.758 8.406-24.95-1.344-33.93c-9.781-9.07-24.97-8.414-33.91 1.344L280 298.9V24.02C280 10.76 269.3 0 256 0S232 10.76 232 24.02v274.9l-54.38-58.95C172.9 234.8 166.5 232.3 160 232.3C154.2 232.3 148.3 234.3 143.7 238.6z"
                                                />
                                            </svg>
                                        </span>
                                    </a>
                                    <a
                                        href="#"
                                        class="btn btn-icon btn-hover-scale rounded-0 border-end btn-active-color-primary btn-sm"
                                        @click.prevent="onCopy(index)"
                                    >
                                        <span
                                            class="svg-icon svg-icon-muted svg-icon-3"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                            >
                                                <path
                                                    d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"
                                                />
                                            </svg>
                                        </span>
                                    </a>
                                    <a
                                        href="#"
                                        @click.stop.prevent="
                                            onClickFavorite(item.id, index)
                                        "
                                        :class="{
                                            'is-favorite': item.is_favorite,
                                        }"
                                        class="favorite btn btn-icon btn-hover-scale rounded-0 border-end btn-active-color-primary btn-sm"
                                    >
                                        <span
                                            class="svg-icon svg-icon-3 favorite"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 576 512"
                                            >
                                                <path
                                                    d="M287.9 0C297.1 0 305.5 5.25 309.5 13.52L378.1 154.8L531.4 177.5C540.4 178.8 547.8 185.1 550.7 193.7C553.5 202.4 551.2 211.9 544.8 218.2L433.6 328.4L459.9 483.9C461.4 492.9 457.7 502.1 450.2 507.4C442.8 512.7 432.1 513.4 424.9 509.1L287.9 435.9L150.1 509.1C142.9 513.4 133.1 512.7 125.6 507.4C118.2 502.1 114.5 492.9 115.1 483.9L142.2 328.4L31.11 218.2C24.65 211.9 22.36 202.4 25.2 193.7C28.03 185.1 35.5 178.8 44.49 177.5L197.7 154.8L266.3 13.52C270.4 5.249 278.7 0 287.9 0L287.9 0zM287.9 78.95L235.4 187.2C231.9 194.3 225.1 199.3 217.3 200.5L98.98 217.9L184.9 303C190.4 308.5 192.9 316.4 191.6 324.1L171.4 443.7L276.6 387.5C283.7 383.7 292.2 383.7 299.2 387.5L404.4 443.7L384.2 324.1C382.9 316.4 385.5 308.5 391 303L476.9 217.9L358.6 200.5C350.7 199.3 343.9 194.3 340.5 187.2L287.9 78.95z"
                                                />
                                            </svg>
                                        </span>
                                    </a>
                                    <a
                                        href="#"
                                        class="btn btn-icon btn-hover-scale rounded-0 border-end btn-active-color-primary btn-sm"
                                        @click.prevent="onThumbsUp(index)"
                                    >
                                        <span
                                            class="svg-icon svg-icon-muted svg-icon-3"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                            >
                                                <path
                                                    d="M96 191.1H32c-17.67 0-32 14.33-32 31.1v223.1c0 17.67 14.33 31.1 32 31.1h64c17.67 0 32-14.33 32-31.1V223.1C128 206.3 113.7 191.1 96 191.1zM512 227c0-36.89-30.05-66.92-66.97-66.92h-99.86C354.7 135.1 360 113.5 360 100.8c0-33.8-26.2-68.78-70.06-68.78c-46.61 0-59.36 32.44-69.61 58.5c-31.66 80.5-60.33 66.39-60.33 93.47c0 12.84 10.36 23.99 24.02 23.99c5.256 0 10.55-1.721 14.97-5.26c76.76-61.37 57.97-122.7 90.95-122.7c16.08 0 22.06 12.75 22.06 20.79c0 7.404-7.594 39.55-25.55 71.59c-2.046 3.646-3.066 7.686-3.066 11.72c0 13.92 11.43 23.1 24 23.1h137.6C455.5 208.1 464 216.6 464 227c0 9.809-7.766 18.03-17.67 18.71c-12.66 .8593-22.36 11.4-22.36 23.94c0 15.47 11.39 15.95 11.39 28.91c0 25.37-35.03 12.34-35.03 42.15c0 11.22 6.392 13.03 6.392 22.25c0 22.66-29.77 13.76-29.77 40.64c0 4.515 1.11 5.961 1.11 9.456c0 10.45-8.516 18.95-18.97 18.95h-52.53c-25.62 0-51.02-8.466-71.5-23.81l-36.66-27.51c-4.315-3.245-9.37-4.811-14.38-4.811c-13.85 0-24.03 11.38-24.03 24.04c0 7.287 3.312 14.42 9.596 19.13l36.67 27.52C235 468.1 270.6 480 306.6 480h52.53c35.33 0 64.36-27.49 66.8-62.2c17.77-12.23 28.83-32.51 28.83-54.83c0-3.046-.2187-6.107-.6406-9.122c17.84-12.15 29.28-32.58 29.28-55.28c0-5.311-.6406-10.54-1.875-15.64C499.9 270.1 512 250.2 512 227z"
                                                />
                                            </svg>
                                        </span>
                                    </a>
                                    <a
                                        href="#"
                                        class="btn btn-icon btn-hover-scale rounded-0 border-end btn-active-color-primary btn-sm"
                                        @click.prevent="onThumbsDown(index)"
                                    >
                                        <span
                                            class="svg-icon svg-icon-muted svg-icon-3"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                            >
                                                <path
                                                    d="M128 288V64.03c0-17.67-14.33-31.1-32-31.1H32c-17.67 0-32 14.33-32 31.1v223.1c0 17.67 14.33 31.1 32 31.1h64C113.7 320 128 305.7 128 288zM481.5 229.1c1.234-5.092 1.875-10.32 1.875-15.64c0-22.7-11.44-43.13-29.28-55.28c.4219-3.015 .6406-6.076 .6406-9.122c0-22.32-11.06-42.6-28.83-54.83c-2.438-34.71-31.47-62.2-66.8-62.2h-52.53c-35.94 0-71.55 11.87-100.3 33.41L169.6 92.93c-6.285 4.71-9.596 11.85-9.596 19.13c0 12.76 10.29 24.04 24.03 24.04c5.013 0 10.07-1.565 14.38-4.811l36.66-27.51c20.48-15.34 45.88-23.81 71.5-23.81h52.53c10.45 0 18.97 8.497 18.97 18.95c0 3.5-1.11 4.94-1.11 9.456c0 26.97 29.77 17.91 29.77 40.64c0 9.254-6.392 10.96-6.392 22.25c0 13.97 10.85 21.95 19.58 23.59c8.953 1.671 15.45 9.481 15.45 18.56c0 13.04-11.39 13.37-11.39 28.91c0 12.54 9.702 23.08 22.36 23.94C456.2 266.1 464 275.2 464 284.1c0 10.43-8.516 18.93-18.97 18.93H307.4c-12.44 0-24 10.02-24 23.1c0 4.038 1.02 8.078 3.066 11.72C304.4 371.7 312 403.8 312 411.2c0 8.044-5.984 20.79-22.06 20.79c-12.53 0-14.27-.9059-24.94-28.07c-24.75-62.91-61.74-99.9-80.98-99.9c-13.8 0-24.02 11.27-24.02 23.99c0 7.041 3.083 14.02 9.016 18.76C238.1 402 211.4 480 289.9 480C333.8 480 360 445 360 411.2c0-12.7-5.328-35.21-14.83-59.33h99.86C481.1 351.9 512 321.9 512 284.1C512 261.8 499.9 241 481.5 229.1z"
                                                />
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                                <div class="d-flex text-muted">
                                    <div
                                        class="d-flex align-items-center border-end me-4"
                                    >
                                        <div class="d-flex me-2">
                                            {{ __('Characters') }}:
                                        </div>
                                        <div
                                            class="d-flex flex-column min-w-25px align-items-end me-4"
                                        >
                                            <span>{{
                                                item.character_count
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex me-2">
                                            {{ __('Words') }}:
                                        </div>
                                        <div
                                            class="d-flex flex-column min-w-25px align-items-end me-4"
                                        >
                                            <span>{{ item.word_count }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center p-10">
                                <div class="d-block fw-bold text-start">
                                    <div
                                        class="choice-text text-gray-800 fw-bold fs-5"
                                        v-html="parsedContent(item.text)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="choices.length === 0" class="col-md-12 h-100">
                        <!--begin::Alert-->
                        <div
                            class="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10 border-none"
                        >
                            <!--begin::Wrapper-->
                            <div class="d-flex flex-column pe-0 pe-sm-10">
                                <!--begin::Content-->
                                <span class="d-none d-lg-block text-gray-600">{{
                                    __(
                                        'Please describe your product / service on the left and click on create.'
                                    )
                                }}</span>
                                <span class="d-lg-none text-gray-600">{{
                                    __(
                                        'Please describe your product / service above and click on create.'
                                    )
                                }}</span>
                                <!--end::Content-->
                            </div>
                            <!--end::Wrapper-->
                            <!--end::Close-->
                        </div>
                        <!--end::Alert-->
                    </div>
                </div>
                <div v-show="loading" class="h-100">
                    <div class="row gx-5 gx-xl-10 h-50">
                        <div
                            class="col-12 d-flex justify-content-center align-items-center"
                        >
                            <CreaitorLoading
                                css-classes="w-100px h-100px"
                            ></CreaitorLoading>
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Wrapper-->
        </div>
        <!--end::Content-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import CreaitorLoading from '@/Components/Loading/CreaitorLoading.vue';
import MarkdownIt from 'markdown-it';
import { useContentCalendarStore } from '@/Stores/ContentCalendarStore';
import { mapWritableState } from 'pinia';
import markdownMixin from '@/Mixins/markdownMixin';

export default defineComponent({
    mixins: [markdownMixin],
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return {
            toast,
        };
    },
    components: {
        CreaitorLoading,
    },
    props: {
        promptTitle: {
            type: String,
            default: 'Input',
        },
        resultTitle: {
            type: String,
            default: 'Choose from the results',
        },
        choices: {
            type: Array,
            default: [],
        },
        inModal: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            markdownIt: new MarkdownIt(),
            copyContent: '',
            copyClicked: false,
            generatingContent: false,
        };
    },
    computed: {
        ...mapWritableState(useContentCalendarStore, ['contentCalendar']),
    },
    methods: {
        async getContentTitle(description) {
            try {
                const result = await axios.post('/api/ai-assistant/compose', {
                    uuid: '2596c5a7-1dcf-49f9-a6c6-4ab8ff8ff44b', //replace the headline generator uuid
                    prompts: {
                        description: description, //description
                    },
                    options: {
                        creativity: 0.8,
                        outputs: 1,
                        toneOfVoice: 'Ironic',
                        targetAudience: 'Businessman 30-50 yo',
                        withEmojis: false,
                        targetLang: 'en',
                        formality: 'default',
                        max_tokens: '260',
                    },
                    lang: 'en',
                    formality: 'default',
                });
                return result?.data?.choices?.[0]?.text || this.__('no title');
            } catch (error) {
                console.log({ error });
                return this.__('no title');
            }
        },
        getSocialMediaType() {
            const types = {
                'Blog Body': 'blog_post',
                'Facebook Caption': 'facebook',
                'LinkedIn Post': 'linkedin',
                'Twitter / X Post': 'twitter',
                'Youtube Video topic idea': 'youtube',
                'Pinterest Description': 'pinterest',
            };

            return types?.[this.promptTitle] || '';
        },
        async saveToCalendar(index) {
            if (this.generatingContent) return;
            // Convert markdown to plain text
            const content = this.renderPlainText(this.choices[index].text);
            this.generatingContent = true;
            // Update only the selected values in contentCalendar
            this.contentCalendar = {
                ...this.contentCalendar, // Keep existing values
                content,
                short_content: content.substring(0, 30) + '...',
                title: await this.getContentTitle(content),
                social_media: this.getSocialMediaType(),
            };
            this.generatingContent = false;
            // eslint-disable-next-line no-undef
            emitter.emit('open-assign-social-media-type-modal');
            // eslint-disable-next-line no-undef
            emitter.emit('document-text-insert');
        },
        onInsert(index) {
            emitter.emit('document-text-insert', this.choices[index].text);
        },
        onCopy(index) {
            // Convert markdown to HTML
            let htmlContent = this.markdownIt.render(this.choices[index].text);

            // Create a temporary element to hold the HTML content
            let tempElement = document.createElement('div');
            tempElement.innerHTML = htmlContent;
            document.body.appendChild(tempElement);

            // Select the content in the temporary element
            let range = document.createRange();
            range.selectNodeContents(tempElement);
            let selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);

            // Copy the selected content to the clipboard
            try {
                document.execCommand('copy');
                this.toast.success(this.__('Content copied to clipboard'));
            } catch (err) {
                this.toast.error(this.__('Failed to copy content'));
            }

            // Clean up by removing the temporary element and clearing the selection
            document.body.removeChild(tempElement);
            selection.removeAllRanges();
        },
        onClickFavorite(id, index) {
            let vm = this;
            vm.choices[index].is_favorite = !vm.choices[index].is_favorite;
            let data = {
                user: {
                    creation_id: this.choices[index].id,
                },
            };
            axios
                .put('/api/users/' + this.$page.props.user.id, data)
                .then((response) => {});
        },
        onThumbsUp(index) {
            this.toast.success(this.__('Thanks for your feedback'));
            axios.put('/api/creations/' + this.choices[index].id, {
                thumbsUp: 1,
            });
        },
        onThumbsDown(index) {
            if (!this.choices[index].refunded) {
                this.toast.success(
                    'Thanks for your feedback.\nWe have refunded ' +
                        this.choices[index].character_count +
                        ' credits'
                );
                axios.put('/api/creations/' + this.choices[index].id, {
                    thumbsDown: 1,
                    characterCount: this.choices[index].character_count,
                });

                this.choices[index].refunded = true;
            }
        },
        parsedContent(content) {
            return this.markdownIt.render(content);
        },
    },
});
</script>

<style lang="scss">
.choice-text {
    white-space: normal;
}
.actions {
    .btn {
        .svg-icon svg path {
            fill: var(--bs-gray-400);
        }

        &.is-favorite {
            .svg-icon svg path {
                fill: var(--bs-link-color);
            }
        }

        &:hover {
            .svg-icon svg path {
                fill: var(--bs-link-color);
            }
        }
    }
    .calendar-dot {
        &:after {
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: #e44a44;
            position: absolute;
            top: -2;
            right: -2;
        }
    }
}
</style>

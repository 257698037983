<template>
    <app-layout :title="__('Content Planner')" :user="$attrs.user" main-class="w-[calc(100vw-var(--kt-sidebar-width)] my-5 px-8">
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1 class="h1 font-weight-bold">
                    {{ __('Content Planner') }} <div class="badge badge-secondary">{{ __('Beta Preview') }}</div>
                </h1>
                <div class="text-muted fs-7 fw-bold">
                    {{ __('Plan your content in advance.') }}
                </div>
            </div>
        </div>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12 content-calendar-wrapper">
                <FullCalendar :options="calendarOptions">
                    <!-- Event day cell content -->
                    <template v-slot:dayCellContent="arg">
                        <div class="day-cell-container">
                            <span class="day-number">
                                {{ arg.dayNumberText }}
                            </span>
                            <button
                                v-if="isFutureDate(arg.date)"
                                class="btn"
                                @click="openAddNewContentModal(arg.date)"
                            >
                                <i class="bi bi-plus"></i>
                            </button>
                        </div>
                    </template>
                    <template v-slot:eventContent="arg">
                        <div
                            v-if="eventIsLoading[arg.event.id]"
                            class="flex items-center justify-center py-8 text-blue-600"
                        >
                            <ALoading />
                        </div>
                        <div v-else>
                            <span class="time mb-2">
                                {{ arg.event.extendedProps.scheduled_time }}
                            </span>
                            <div class="flex items-center mb-2">
                                <i
                                    :class="[
                                        contentCalendarStore.getSocialMediaIcon(
                                            arg.event.extendedProps.social_media
                                        ),
                                    ]"
                                    style="color: #0b66c2"
                                ></i>
                                <h4 class="ms-2 mb-0 truncate">{{ arg.event.title }}</h4>
                            </div>
                            <p class="mb-2">
                                {{ arg.event.extendedProps.short_content }}
                            </p>
                            <span
                                class="badge fw-semibold fs-8 px-2 cursor-default text-capitalize"
                                :class="[
                                    contentCalendarStore.getStatusClass(
                                        arg.event.extendedProps.status
                                    ),
                                ]"
                                data-bs-toggle="tooltip"
                                :data-bs-original-title="
                                    arg.event.extendedProps.status
                                "
                                data-kt-initialized="1"
                            >
                                {{ arg.event.extendedProps.status }}
                            </span>
                        </div>
                    </template>
                </FullCalendar>
            </div>
        </div>
        <AssignSocialMediaType />
        <AddNewContentModal />
        <OutputSelectionModal />
        <ContentPreviewModal />
        <ContentEditModal />
        <TimeSelectionModal />
        <EventPreviewModal />
    </app-layout>
</template>
<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import AssignSocialMediaType from "@/Modals/ContentCalendar/AssignSocialMediaType.vue";
import AddNewContentModal from '@/Modals/ContentCalendar/AddNewContentModal.vue';
import OutputSelectionModal from '@/Modals/ContentCalendar/OutputSelectionModal.vue';
import ContentPreviewModal from '@/Modals/ContentCalendar/ContentPreviewModal.vue';
import ContentEditModal from '@/Modals/ContentCalendar/ContentEditModal.vue';
import TimeSelectionModal from '@/Modals/ContentCalendar/TimeSelectionModal.vue';
import EventPreviewModal from '@/Modals/ContentCalendar/EventPreviewModal.vue';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import moment from 'moment';
import { useContentCalendarStore } from '@/Stores/ContentCalendarStore';
import { mapWritableState } from 'pinia';
import ALoading from "@/Components/Atoms/ALoading.vue";

export default defineComponent({
    name: 'ContentCalendarIndex',

    components: {
        AssignSocialMediaType,
        ALoading,
        AddNewContentModal,
        AppLayout,
        ContentEditModal,
        ContentPreviewModal,
        EventPreviewModal,
        FullCalendar,
        OutputSelectionModal,
        TimeSelectionModal,
    },

    setup() {
        const contentCalendarStore = useContentCalendarStore();
        return {
            contentCalendarStore,
        };
    },

    data() {
        const userLocale = this.$attrs?.user?.user_info?.language || 'en';
        return {
            calendarArg: {},
            calendarOptions: {
                // plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                plugins: [dayGridPlugin],
                initialView: 'dayGridMonth',
                headerToolbar: {
                    left: 'title',
                    center: '',
                    // right: 'prev,next timeGridDay,timeGridWeek,dayGridMonth',
                    right: 'prev,next',
                },
                locales: allLocales,
                locale: userLocale,
                buttonText: this.getCapitalizedButtonText(userLocale),
                events: [],
                datesSet: this.handleDatesSet,
                eventClick: this.handleEventClick,
                // views: {
                //     // day: {
                //     //     dayHeaderFormat: { weekday: 'short', day: 'numeric' },
                //     // },
                //     // timeGridWeek: {
                //     //     dayHeaderFormat: { weekday: 'short', day: 'numeric' },
                //     // },
                //     month: {},
                // },
            },
            eventCache: new Map(), // Add this line to create a cache
            eventIsLoading: [],
        };
    },

    mounted() {
        let vm = this;
        // eslint-disable-next-line no-undef
        emitter.on('content-calendar-reload-event-data', () => {
            // Remove the events from the cache
            vm.eventCache.clear();
            vm.handleDatesSet(vm.calendarArg);
        });
    },

    beforeUnmount() {
        // eslint-disable-next-line no-undef
        emitter.off('content-calendar-reload-event-data');
    },

    computed: {
        ...mapWritableState(useContentCalendarStore, ['contentCalendar']),
    },

    methods: {
        getCapitalizedButtonText(locale) {
            const localeData = allLocales.find((l) => l.code === locale);
            if (!localeData)
                return { day: 'Day', week: 'Week', month: 'Month' };
            return {
                day: this.capitalize(localeData.buttonText.day || 'Day'),
                week: this.capitalize(localeData.buttonText.week || 'Week'),
                month: this.capitalize(localeData.buttonText.month || 'Month'),
            };
        },
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        handleDatesSet(arg) {
            const startDate = arg.start;
            const endDate = arg.end;
            const viewType = arg.view.type;
            this.calendarArg = arg;
            this.fetchEvents(startDate, endDate, viewType);
        },
        async fetchEvents(startDate, endDate, viewType) {
            let vm = this;
            // eslint-disable-next-line no-undef
            const apiRoute = route('content-calendar.get-events', {
                start: moment(startDate).format('YYYY-MM-DD'),
                end: moment(endDate).format('YYYY-MM-DD'),
                view_type: viewType,
            });
            //eslint-disable-next-line no-undef
            await axios
                .get(apiRoute)
                .then((response) => {
                    vm.calendarOptions.events = response.data.events;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async handleEventClick(info) {
            const event = info.event;
            const eventId = parseInt(event.id);
            let eventDetails;
            // Check if the event details are in the cache
            if (this.eventCache.has(eventId)) {
                eventDetails = this.eventCache.get(eventId);
            } else {
                this.eventIsLoading[eventId] = true;
                // If not in cache, fetch from the API
                try {
                    // eslint-disable-next-line no-undef
                    const response = await axios.get(
                        // eslint-disable-next-line no-undef
                        route('content-calendar.get-event', eventId)
                    );
                    eventDetails = response.data;
                    // Store the fetched details in the cache
                    this.eventCache.set(eventId, eventDetails);
                    this.eventIsLoading[eventId] = false;
                } catch (error) {
                    console.error('Error fetching event details:', error);
                    this.eventIsLoading[eventId] = false;
                    return; // Exit the method if there's an error
                }
            }
            const formattedDate = moment(eventDetails.created_at).format(
                'YYYY-MM-DD HH:mm:ss'
            );
            this.contentCalendar = {
                attachments: eventDetails.attachments || [],
                content: eventDetails.content,
                created: formattedDate,
                icon: null,
                id: eventDetails.id,
                scheduled_at: eventDetails.scheduled_at,
                social_media: eventDetails.social_media,
                status: eventDetails.status,
                title: eventDetails.title,
            };
            // eslint-disable-next-line no-undef
            emitter.emit('open-content-calendar-event-preview-modal');
        },
        isFutureDate(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Set to the start of the day
            return date >= today;
        },
        openAddNewContentModal(date) {
            this.contentCalendarStore.$reset();
            this.contentCalendar.scheduled_at = moment(date).format(
                'YYYY-MM-DD HH:mm:ss'
            );
            // eslint-disable-next-line no-undef
            emitter.emit('open-add-new-content-calendar-modal');
        },
    },
});
</script>

<style lang="scss">
.content-calendar-wrapper {
    // FullCalendar button group
    .fc-button-group {
        border: 1px solid #e4e6ef;
        border-radius: 16px;
        overflow: hidden;
        padding: 8px;
        .fc-button {
            background-color: transparent;
            color: #101d36;
            font-size: 14px;
            font-weight: 600;
            border: none;
            border-radius: 10px;
            padding: 8px 16px;
            transition: all 0.3s ease;
            &.fc-prev-button,
            &.fc-next-button,
            &.fc-button-active,
            &:focus {
                background-color: #fff;
                box-shadow: 0 1px 3px rgba(16, 24, 40, 0.05) !important;
                border-radius: 10px;
            }
            &.fc-prev-button,
            &.fc-next-button {
                padding: 8px;
                span {
                    position: relative;
                    top: -2px;
                }
            }
            &.fc-prev-button {
                margin-right: 8px;
            }
            &.fc-button-active,
            &:focus {
                color: #0186d2;
            }
        }
    }
    // Calendar table
    .fc-theme-standard {
        td,
        th,
        .fc-scrollgrid {
            border-color: #e4e6ef;
        }
    }
    .fc-scrollgrid {
        background-color: #fff;
        border-radius: 8px;
        border-color: #e4e6ef;
        overflow: hidden;
    }
    .fc-col-header-cell-cushion {
        font-size: 14px;
        color: #5e6278;
        text-transform: uppercase;
        padding: 10px 0;
    }
    .fc-daygrid-day {
        padding: 10px;
        .fc-daygrid-day-top {
            flex-direction: row;
        }
        .fc-daygrid-day-number {
            width: 100%;
            padding: 0;
        }
        .day-cell-container {
            display: flex;
            justify-content: space-between;
            .day-number {
                font-size: 16px;
                color: #5e6278;
                text-transform: uppercase;
                padding: 8px;
            }
            .btn {
                padding: 6px !important;
                border-radius: 8px;
                background: #e6f5fe;
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.3s, visibility 0.3s;
                i {
                    color: #0186d2;
                    font-size: 32px;
                    padding: 0;
                }
            }
        }
        &:hover .btn {
            opacity: 1;
            visibility: visible;
        }
    }
    td.fc-day.fc-day-today {
        background: #fff;
    }
    .fc-dayGridMonth-view {
        .fc-day-today {
            .day-number {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35px;
                height: 35px;
                border-radius: 8px;
                color: #fff !important;
                background-color: #0186d2;
            }
        }
    }
    .fc-event {
        background: #fbfbfb;
        border-color: #e4e6ef;
        border-radius: 4px;
        padding: 8px;
        margin: 8px 0 !important;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
            background-color: #e6f5fe;
            border-color: #0186d2;
        }
        .time {
            display: block;
            font-size: 14px;
            color: #5e6278;
        }
        i {
            font-size: 20px;
        }
        h4 {
            font-size: 16px;
        }
        p {
            font-size: 14px;
            color: #5e6278;
        }
        .badge {
            border-radius: 4px;
            font-size: 12px;
            text-transform: capitalize;
            color: #585757;
            background-color: #f9f9f9;
            border: 1px solid #585757;
            &.badge-success {
                background-color: #e5f8eb;
                color: #00a12d;
                border-color: #00a12d;
            }
            &.badge-warning {
                background-color: #fff4e8;
                color: #c16b05;
                border-color: #c16b05;
            }
        }
    }
}
</style>

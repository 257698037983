<template>
    <div
        class="modal fade"
        id="kt_modal_calendar_content_edit"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div
            class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable w-[760px]"
        >
            <!--begin::Modal content-->
            <div class="modal-content p-5">
                <!--begin::Modal header-->
                <div class="modal-header bg-white p-0 pb-5 mb-5">
                    <!--begin::Heading-->
                    <template v-if="isDuplicating">
                        <h2>{{ __('Duplicate') }}</h2>
                        <div>{{ __('Step') }} 1/2</div>
                    </template>
                    <h2 v-else>{{ __('Edit post') }}</h2>

                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body p-0 m-0">
                    <div>
                        <div class="edit-post-content">
                            <div class="d-flex">
                                <div class="w-25 me-5">
                                    <label
                                        class="form-label"
                                        for="socialMediaType"
                                    >
                                        {{ __('Type') }}
                                    </label>
                                    <select
                                        v-model="contentCalendar.social_media"
                                        id="socialMediaType"
                                        class="form-select"
                                    >
                                        <option></option>
                                        <option
                                            :value="socialMedia.value"
                                            v-for="socialMedia in socialMedias"
                                            :key="socialMedia.value"
                                        >
                                            {{ __(socialMedia.name) }}
                                        </option>
                                    </select>
                                </div>
                                <div class="w-25 me-5">
                                    <label
                                        class="form-label"
                                        for="contentStatus"
                                    >
                                        {{ __('Status') }}
                                    </label>
                                    <select
                                        id="socialMediaType"
                                        class="form-select"
                                        v-model="contentCalendar.status"
                                    >
                                        <option
                                            v-for="contentStatus in contentStatuses"
                                            :key="contentStatus.value"
                                            :value="contentStatus.value"
                                        >
                                            {{ __(contentStatus.name) }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="editor-wrapper mt-5 mb-5">
                                <editor-content
                                    :editor="editor"
                                    class="editor-content p-3 border rounded"
                                />
                                <div class="editor-footer d-flex justify-content-end mt-2 text-muted">
                                    <div class="d-flex me-4">
                                        <div class="me-2">{{ __('Characters') }}:</div>
                                        <div>{{ editor?.storage.characterCount.characters() || 0 }}</div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="me-2">{{ __('Words') }}:</div>
                                        <div>{{ editor?.storage.characterCount.words() || 0 }}</div>
                                    </div>
                                </div>
                            </div>
                            <file-pond
                                v-if="!attachmentUrl"
                                name="file"
                                ref="pond"
                                class-name="rounded filepond-edit-post"
                                :label-idle="dropLabel"
                                :label-file-loading="__('Loading')"
                                :label-file-processing-complete="__('Upload complete')"
                                :label-file-processing-aborted="__('Upload cancelled')"
                                :label-file-processing-error="__('Error during upload')"
                                :label-invalid-field="__('Field contains invalid files')"
                                :label-ta-to-cancel="__('tap to cancel')"
                                :label-ta-to-retry="__('tap to retry')"
                                :label-ta-to-undo="__('tap to undo')"
                                allow-multiple="false"
                                max-files="1"
                                accepted-file-types="image/jpeg, image/png"
                                credits="false"
                                v-bind:files="files"
                                styleButtonRemoveItemPosition="right"
                                @processfile="handleFileFinish"
                            />
                            <div
                                v-else
                                class="image-wrapper position-relative rounded-3 overflow-hidden w-100 min-h-24 bg-gray-100 !outline-none"
                            >
                                <div v-show="!imageLoading">
                                    <div
                                        class="image-action-buttons position-absolute top-0 end-0 d-flex flex-nowrap gap-2 pt-5 pe-5"
                                    >
                                        <button
                                            class="bg-white rounded-3 px-3 py-2 d-flex align-items-center"
                                            @click="openCropModal"
                                        >
                                            <i
                                                class="bi bi-crop text-dark fs-4"
                                            ></i>
                                        </button>
                                        <button
                                            class="bg-white rounded-3 px-3 py-2 d-flex align-items-center"
                                            @click="removeAttachment"
                                        >
                                            <i
                                                class="bi bi-trash text-dark fs-4"
                                            ></i>
                                        </button>
                                        <button
                                            class="bg-white rounded-3 px-3 py-2 d-flex align-items-center"
                                            @click="downloadImage"
                                        >
                                            <i
                                                class="bi bi-download text-dark fs-4"
                                            ></i>
                                        </button>
                                    </div>
                                    <img
                                        ref="imagePreview"
                                        class="w-100 object-fit-cover"
                                        :src="attachmentUrl"
                                        @load="imageLoading = false"
                                        @error="imageLoading = false"
                                    />
                                </div>
                                <div v-show="imageLoading">
                                    <CreaitorLoading />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Modal body-->
                <div class="modal-footer border-0">
                    <div class="d-flex justify-content-end m-0">
                        <button
                            class="btn btn-secondary me-3"
                            @click="closeModal"
                        >
                            {{ __('Cancel') }}
                        </button>
                        <button
                            class="btn btn-primary"
                            :class="{
                                disabled: inActiveSaveButton,
                            }"
                            @click="updateCalendarContent"
                        >
                            {{ nextStep }}
                        </button>
                    </div>
                </div>
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
    <ImageCropper
        v-if="attachmentUrl"
        :imageUrl="attachmentUrl"
        @save-cropping="handleSaveCropping"
    />
</template>

<script>
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';
import { useContentCalendarStore } from '@/Stores/ContentCalendarStore';
import { mapState, mapWritableState } from 'pinia';
import Description from '@/Components/Fields/Description.vue';
import CreaitorLoading from '@/Components/Loading/CreaitorLoading.vue';
import vueFilePond, { setOptions } from 'vue-filepond';
import ImageCropper from '@/Modals/ContentCalendar/ImageCropper.vue';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { useToast } from 'vue-toastification';
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import CharacterCount from '@tiptap/extension-character-count';

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);

export default defineComponent({
    components: {
        Description,
        FilePond,
        ImageCropper,
        CreaitorLoading,
        EditorContent,
    },
    setup() {
        const contentCalendarStore = useContentCalendarStore();
        const toast = useToast();

        return {
            contentCalendarStore,
            toast,
        };
    },
    data() {
        return {
            dropLabel: `<div class="drop-section">
                <div class="d-inline-flex rounded border border-secondary py-1 px-4">
                    <i class="bi bi-cloud-upload fs-1 position-relative"></i>
                </div>
                <p class="mb-0 mt-3">
                    <span>${this.__('Click to upload')}</span>
                    ${this.__('or drag and drop <br> PNG or JPEG')}
                </p>
            </div>`,
            files: [],
            modal: {},
            saveProgress: false,
            fileProcessing: false,
            imageLoading: true,
            editor: null,
        };
    },
    computed: {
        ...mapState(useContentCalendarStore, ['isEditing', 'contentStatuses']),
        ...mapWritableState(useContentCalendarStore, [
            'contentCalendar',
            'isDuplicating',
            'socialMedias',
        ]),
        inActiveSaveButton() {
            return (
                !this.contentCalendar.content ||
                !this.contentCalendar.social_media
            );
        },
        nextStep() {
            return this.isDuplicating ? this.__('Next') : this.__('Save');
        },
        attachmentUrl() {
            return this.contentCalendar?.attachments[0]?.url || '';
        },
    },
    mounted() {
        let vm = this;

        this.modal = new Modal(
            document.getElementById('kt_modal_calendar_content_edit')
        );

        // Add event listener for modal hidden event
        document
            .getElementById('kt_modal_calendar_content_edit')
            .addEventListener('hidden.bs.modal', this.handleModalHidden);

        // eslint-disable-next-line no-undef
        emitter.on('open-content-calendar-content-edit-modal', () =>
            vm.modal.show()
        );
        // eslint-disable-next-line no-undef
        emitter.on('close-content-calendar-content-edit-modal', () =>
            vm.modal.hide()
        );

        setOptions({
            server: {
                url: '/filepond/api',
                process: '/process',
                revert: '/process',
                patch: '?patch=',
                headers: {
                    'X-CSRF-TOKEN': document
                        .querySelector('meta[name="csrf-token"]')
                        .getAttribute('content'),
                },
            },
        });

        // Initialize TipTap editor
        this.editor = new Editor({
            extensions: [
                StarterKit,
                CharacterCount,
            ],
            content: this.parseContent(this.contentCalendar.content) || '',
            onUpdate: ({ editor }) => {
                if (editor.isFocused) {
                    // Only update store if change came from user input
                    const content = editor.getHTML()
                        .replace(/<p>/g, '')
                        .replace(/<\/p>/g, '\n')
                        .replace(/<br>/g, '\n')
                        .trim();
                    this.contentCalendar.content = content;
                }
            },
        });
    },
    beforeUnmount() {
        // Remove the event listener when component is unmounted
        document
            .getElementById('kt_modal_calendar_content_edit')
            .removeEventListener('hidden.bs.modal', this.handleModalHidden);

        // eslint-disable-next-line no-undef
        emitter.off('open-content-calendar-content-edit-modal');
        // eslint-disable-next-line no-undef
        emitter.off('close-content-calendar-content-edit-modal');

        this.editor?.destroy();
    },
    methods: {
        handleModalHidden() {
            // This method will be called whenever the modal is hidden
            if (!this.saveProgress) {
                if (!this.isEditing) {
                    this.removeAttachment();
                }
                this.contentCalendarStore.$reset();
            }
            // Reset the flag
            this.saveProgress = false;
        },
        closeModal() {
            this.saveProgress = true;
            this.modal.hide();

            if (this.isEditing || this.isDuplicating) {
                // eslint-disable-next-line no-undef
                emitter.emit('open-content-calendar-event-preview-modal');
            } else {
                // eslint-disable-next-line no-undef
                emitter.emit('open-content-calendar-content-preview-modal');
            }
        },
        updateCalendarContent() {
            let vm = this;

            if (!this.contentCalendar.id && !this.isDuplicating) {
                this.saveProgress = true;
                this.modal.hide();
                // eslint-disable-next-line no-undef
                emitter.emit('open-content-calendar-content-preview-modal');

                return;
            } else if (this.isDuplicating) {
                this.saveProgress = true;
                this.modal.hide();
                // eslint-disable-next-line no-undef
                emitter.emit('open-content-calendar-time-selection-modal');

                return;
            }

            // eslint-disable-next-line no-undef
            axios
                .put(
                    // eslint-disable-next-line no-undef
                    route('content-calendar.update', this.contentCalendar.id),
                    this.contentCalendar
                )
                .then((response) => {
                    vm.toast.success(response.data.message);
                    vm.saveProgress = true;
                    vm.modal.hide();
                    vm.contentCalendar.attachments =
                        response.data.data.attachments;
                    // eslint-disable-next-line no-undef
                    emitter.emit('open-content-calendar-event-preview-modal');
                    // eslint-disable-next-line no-undef
                    emitter.emit('content-calendar-reload-event-data');
                })
                .catch((error) => {
                    vm.toast.error(error.response.data.message);
                });
        },
        handleFileFinish() {
            const vm = this;

            this.fileProcessing = true;

            // eslint-disable-next-line no-undef
            axios
                // eslint-disable-next-line no-undef
                .post(route('content-calendar.process-file'), {
                    server_id: this.$refs.pond.getFile().serverId,
                })
                .then((response) => {
                    vm.fileProcessing = false;
                    vm.contentCalendar.attachments[0] =
                        response.data.attachment;
                })
                .catch(() => {
                    vm.fileProcessing = false;
                    vm.toast.error(
                        vm.__('Some error occurred while uploading')
                    );
                });
        },
        removeAttachment() {
            //Don't remove the file or update the data while a user updates the content, do these things only when saving.
            //Instead only remove the attachments from the store.
            if (this.isEditing) {
                this.contentCalendar.attachments = [];

                return false;
            }
            const vm = this;
            const relativePath =
                this.contentCalendar?.attachments[0]?.relative_path || '';
            const payload = {
                relative_path: relativePath,
            };

            if (!relativePath) {
                return false;
            }

            this.fileProcessing = true;

            // eslint-disable-next-line no-undef
            axios
                // eslint-disable-next-line no-undef
                .delete(
                    // eslint-disable-next-line no-undef
                    route('content-calendar.remove-file'),
                    { data: payload }
                )
                .then(() => {
                    vm.fileProcessing = false;
                    vm.contentCalendar.attachments = [];
                    vm.files = [];
                })
                .catch(() => {
                    vm.fileProcessing = false;
                    vm.toast.error(vm.__('Some error occurred'));
                });
        },
        downloadImage() {
            const url = this.contentCalendar?.attachments[0]?.url || '';

            if (!url) {
                return false;
            }

            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = url;
            link.download = ''; // You can specify a filename here if needed
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        openCropModal() {
            // eslint-disable-next-line no-undef
            emitter.emit('open-content-calendar-image-crop-modal');
        },
        handleSaveCropping(canvas) {
            const imageUrl = canvas.toDataURL('image/png');
            this.loadCroppingPreview(imageUrl);

            // eslint-disable-next-line no-undef
            emitter.emit('close-content-calendar-image-crop-modal');

            this.removeAttachment();
            this.saveCroppingPreview(imageUrl);
        },
        loadCroppingPreview(imageUrl) {
            const previewElement = this.$refs.imagePreview;
            previewElement.src = imageUrl;
        },
        saveCroppingPreview(imageUrl) {
            const vm = this;
            // eslint-disable-next-line no-undef
            axios
                // eslint-disable-next-line no-undef
                .post(route('content-calendar.process-file'), {
                    base64_image: imageUrl,
                })
                .then((response) => {
                    vm.fileProcessing = false;
                    vm.contentCalendar.attachments[0] =
                        response.data.attachment;
                })
                .catch(() => {
                    vm.fileProcessing = false;
                    vm.toast.error(
                        vm.__('Some error occurred while uploading')
                    );
                });
        },
        parseContent(content) {
            if (!content) return '';

            // Convert line breaks to paragraphs
            const paragraphs = content.split('\n').filter(p => p.trim());
            return paragraphs.map(p => `<p>${p}</p>`).join('');
        },
    },
    watch: {
        'contentCalendar.content': {
            immediate: true,
            handler(newContent, oldContent) {
                if (this.editor && newContent !== oldContent) {
                    // Only set content on initial load or when content changes from outside
                    if (!oldContent || !this.editor.isFocused) {
                        const parsedContent = this.parseContent(newContent);
                        this.editor.commands.setContent(parsedContent || '');
                    }
                }
            }
        }
    },
});
</script>

<style lang="scss">
#kt_modal_calendar_content_edit {
    .modal-dialog {
        .modal-header {
            z-index: 1;
        }

        .modal-body {
            .edit-post-content {
                .editor-wrapper {
                    .editor-content {
                        min-height: 320px;
                        background: white;

                        .ProseMirror {
                            min-height: 300px;
                            outline: none;
                            padding: 1rem;

                            > * + * {
                                margin-top: 0.75em;
                            }

                            &:focus {
                                outline: none;
                            }

                            p {
                                margin: 0;
                                margin-bottom: 0.5rem;
                                min-height: 1.5em;
                            }
                        }
                    }
                }
                .filepond-edit-post {
                    height: 200px;
                    background-color: #fafbfb;
                    cursor: pointer;

                    .filepond--drop-label {
                        height: 200px;
                        transition: background-color 0.2s ease;

                        &:hover {
                            background-color: #f0f0f0;  // Light background on hover
                        }

                        label {
                            font-size: 14px;
                            color: #0186d2;
                        }
                    }

                    .drop-section {
                        i {
                            color: #0186d2;
                            bottom: -2px;
                        }

                        p {
                            font-size: 14px;
                            color: #45577b;

                            span {
                                color: #0186d2;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

import { defineStore } from 'pinia';

export const useContentCalendarStore = defineStore('contentCalendar', {
    state: () => ({
        contentCalendar: {
            attachments: [], //objects with server_id and filename
            content: null,
            created: null,
            icon: null,
            id: null,
            short_content: null,
            title: null,
            scheduled_at: null,
            social_media: null,
            status: null,
        },
        contentStatuses: [
            {
                value: 'canceled',
                name: 'Canceled',
                class: 'badge-danger',
            },
            {
                value: 'draft',
                name: 'Draft',
                class: 'badge-info',
            },
            {
                value: 'pending',
                name: 'Pending',
                class: 'badge-warning',
            },
            {
                value: 'posted',
                name: 'Posted',
                class: 'badge-success',
            },
        ],
        isDuplicating: false,
        isRescheduling: false,
        socialMedias: [
            {
                value: 'blog_post',
                name: 'Blog post',
                icon: 'bi bi-file-earmark-text',
                imageAspectRatios: [
                    {
                        label: 'Square',
                        value: '1:1',
                    },
                    {
                        label: 'Landscape',
                        value: '16:9',
                    },
                    {
                        label: 'Portrait',
                        value: '9:16',
                    },
                ],
            },
            {
                value: 'facebook',
                name: 'Facebook',
                icon: 'bi bi-facebook',
                imageAspectRatios: [
                    {
                        label: 'Square',
                        value: '1:1',
                    },
                    {
                        label: 'Landscape',
                        value: '16:9',
                    },
                    {
                        label: 'Portrait',
                        value: '9:16',
                    },
                ],
            },
            {
                value: 'instagram',
                name: 'Instagram',
                icon: 'bi bi-instagram',
                imageAspectRatios: [
                    {
                        label: 'Square',
                        value: '1:1',
                    },
                    {
                        label: 'Portrait',
                        value: '4:5',
                    },
                    {
                        label: 'Vertical',
                        value: '9:16',
                    },
                ],
            },
            {
                value: 'linkedin',
                name: 'LinkedIn',
                icon: 'bi bi-linkedin',
                imageAspectRatios: [
                    {
                        label: 'Square',
                        value: '1:1',
                    },
                    {
                        label: 'Landscape',
                        value: '1.91:1',
                    },
                ],
            },
            {
                value: 'pinterest',
                name: 'Pinterest',
                icon: 'bi bi-pinterest',
                imageAspectRatios: [
                    {
                        label: 'Portrait',
                        value: '2:3',
                    },
                ],
            },
            {
                value: 'twitter',
                name: 'Twitter',
                icon: 'bi bi-twitter',
                imageAspectRatios: [
                    {
                        label: 'Landscape',
                        value: '16:9',
                    },
                    {
                        label: 'Square',
                        value: '1:1',
                    },
                ],
            },
            {
                value: 'youtube',
                name: 'YouTube',
                icon: 'bi bi-youtube',
                imageAspectRatios: [
                    {
                        label: 'Landscape',
                        value: '16:9',
                    },
                ],
            },
        ],
    }),
    getters: {
        getShortContent: (state) => {
            return `${state?.contentCalendar?.content?.substring(0, 30)}...`;
        },
        getStatusClass: (state) => {
            return (status) => {
                return (
                    state.contentStatuses.filter(
                        (stat) => stat.value == status
                    )[0]?.class ?? 'badge-info'
                );
            };
        },
        getSocialMedia: (state) => {
            return (socialMedia) => {
                return state.socialMedias.find(
                    (media) => media.value === socialMedia
                );
            };
        },
        getSocialMediaIcon: (state) => {
            return (socialMedia) => {
                const media = state.getSocialMedia(socialMedia);
                return media?.icon || '';
            };
        },
        getSocialMediaName: (state) => {
            return (socialMedia) => {
                const media = state.getSocialMedia(socialMedia);
                return media?.name || '';
            };
        },
        isContentPosted: (state) => {
            return state?.contentCalendar?.status === 'posted';
        },
        isContentPending: (state) => {
            return state?.contentCalendar?.status === 'pending';
        },
        isContentDraft: (state) => {
            return state?.contentCalendar?.status === 'draft';
        },
        isContentCanceled: (state) => {
            return state?.contentCalendar?.status === 'canceled';
        },
        isEditing: (state) => {
            return !!state?.contentCalendar?.id;
        },
    },
});
<template>
    <div
        class="modal fade"
        id="kt_modal_time_selection"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered">
            <!--begin::Modal content-->
            <div class="modal-content p-5">
                <!--begin::Modal header-->
                <div class="modal-header bg-white p-0 pb-5 mb-5">
                    <!--begin::Heading-->
                    <div>
                        <h2>{{ modalHeading }}</h2>
                        <div v-if="!isRescheduling">
                            <strong>{{ __('Step') }}</strong> 2/2
                        </div>
                    </div>
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body p-0 m-0">
                    <div>
                        <div
                            class="calendar-time-selection-date flex justify-center"
                        >
                            <Calendar
                                v-model="scheduledDate"
                                :weekday-format="'short'"
                                class="p-0"
                            />
                        </div>
                        <div class="py-5 calendar-time-selection">
                            <h5>
                                {{ __('Publish time') }}
                            </h5>
                            <div
                                class="d-flex justify-content-between align-items-center"
                            >
                                <div class="w-100">
                                    <input
                                        type="number"
                                        min="0"
                                        max="12"
                                        v-model="scheduledHour"
                                    />
                                    <label for="scheduledHour">
                                        {{ __('Hour') }}
                                    </label>
                                </div>
                                <span
                                    class="mx-3 fs-1 fw-bolder color-secondary"
                                >
                                    :
                                </span>
                                <div class="me-3 w-100">
                                    <input
                                        type="number"
                                        min="0"
                                        max="60"
                                        v-model="scheduledMinute"
                                    />
                                    <label for="scheduledTimePeriod">
                                        {{ __('Minute') }}
                                    </label>
                                </div>

<!--                             TODO: enable later based on location -->
<!--                                <div-->
<!--                                    class="calendar-time-selection-period overflow-hidden w-50"-->
<!--                                >-->
<!--                                    <div-->
<!--                                        class="calendar-time-selection-period-item d-flex p-1 justify-content-center"-->
<!--                                        :class="{-->
<!--                                            active:-->
<!--                                                scheduledTimePeriod === 'am',-->
<!--                                        }"-->
<!--                                    >-->
<!--                                        <input-->
<!--                                            type="radio"-->
<!--                                            value="am"-->
<!--                                            v-model="scheduledTimePeriod"-->
<!--                                            class="stretched-link"-->
<!--                                            id="am"-->
<!--                                        />-->
<!--                                        <label for="am">-->
<!--                                            {{ __('AM') }}-->
<!--                                        </label>-->
<!--                                    </div>-->
<!--                                    <div-->
<!--                                        class="calendar-time-selection-period-item d-flex border-top p-1 justify-content-center"-->
<!--                                        :class="{-->
<!--                                            active:-->
<!--                                                scheduledTimePeriod === 'pm',-->
<!--                                        }"-->
<!--                                    >-->
<!--                                        <input-->
<!--                                            type="radio"-->
<!--                                            value="pm"-->
<!--                                            v-model="scheduledTimePeriod"-->
<!--                                            class="stretched-link"-->
<!--                                            id="pm"-->
<!--                                        />-->
<!--                                        <label for="pm">-->
<!--                                            {{ __('PM') }}-->
<!--                                        </label>-->
<!--                                    </div>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div
                            class="d-flex justify-content-end border-top pt-5 mt-5"
                        >
                            <button
                                class="btn btn-secondary me-3"
                                @click="closeModal"
                            >
                                {{ __('Cancel') }}
                            </button>
                            <button
                                class="btn btn-primary"
                                @click="createOrUpdateCalendar"
                            >
                                {{ saveButtonLabel }}
                            </button>
                        </div>
                    </div>

                    <!--end::Modal body-->
                </div>
                <!--end::Modal content-->
            </div>
            <!--end::Modal dialog-->
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';
import { useContentCalendarStore } from '@/Stores/ContentCalendarStore';
import { mapState, mapWritableState } from 'pinia';
import moment from 'moment';
import { useToast } from 'vue-toastification';
import { Calendar } from '@/Components/ui/calendar';
import { today, getLocalTimeZone, CalendarDate } from '@internationalized/date';
import { router } from '@inertiajs/vue3';

export default defineComponent({
    components: {
        Calendar,
    },
    setup() {
        const contentCalendarStore = useContentCalendarStore();
        const toast = useToast();

        return {
            contentCalendarStore,
            toast,
        };
    },
    data() {
        return {
            loading: false,
            modal: {},
            scheduledDate: today(getLocalTimeZone()),
            scheduledHour: null,
            scheduledMinute: null,
            scheduledTimePeriod: null,
            saveProgress: false,
        };
    },
    mounted() {
        let vm = this;

        this.modal = new Modal(
            document.getElementById('kt_modal_time_selection')
        );

        // Add event listener for modal hidden event
        document
            .getElementById('kt_modal_time_selection')
            .addEventListener('hidden.bs.modal', this.handleModalHidden);

        // eslint-disable-next-line no-undef
        emitter.on('open-content-calendar-time-selection-modal', () =>
            vm.modal.show()
        );
        // eslint-disable-next-line no-undef
        emitter.on('close-content-calendar-time-selection-modal', () =>
            vm.modal.hide()
        );
    },
    watch: {
        'contentCalendar.scheduled_at': {
            handler(newScheduledAt) {
                if (newScheduledAt) {
                    const currentTime = moment();
                    const scheduledTime = moment(newScheduledAt);
                    if (scheduledTime.isBefore(currentTime)) {
                        this.updateScheduledDateTime(
                            currentTime.format('YYYY-MM-DD HH:mm:ss')
                        );
                    } else {
                        this.updateScheduledDateTime(
                            scheduledTime.format('YYYY-MM-DD HH:mm:ss')
                        );
                    }
                } else {
                    // Set default date to current time
                    const currentTime = moment();
                    this.updateScheduledDateTime(
                        currentTime.format('YYYY-MM-DD HH:mm:ss')
                    );
                }
            },
            immediate: true,
        },
    },
    beforeUnmount() {
        // Remove the event listener when component is unmounted
        document
            .getElementById('kt_modal_time_selection')
            .removeEventListener('hidden.bs.modal', this.handleModalHidden);

        // eslint-disable-next-line no-undef
        emitter.off('open-content-calendar-time-selection-modal');
        // eslint-disable-next-line no-undef
        emitter.off('close-content-calendar-time-selection-modal');
    },
    computed: {
        ...mapState(useContentCalendarStore, ['isEditing']),
        ...mapWritableState(useContentCalendarStore, [
            'contentCalendar',
            'isDuplicating',
            'isRescheduling',
        ]),
        modalHeading() {
            return this.isEditing
                ? this.__('Reschedule')
                : this.__('Add to content planner');
        },
        saveButtonLabel() {
            return this.isEditing
                ? this.__('Save')
                : this.__('Add to calendar');
        },
        formatedScheduledDate() {
            // Extract year, month, and day
            const { year, month, day } = this.scheduledDate;

            // Convert to a string in the format YYYY-MM-DD
            const formattedDate = `${year}-${String(month).padStart(
                2,
                '0'
            )}-${String(day).padStart(2, '0')}`;

            return moment(
                `${formattedDate} ${this.scheduledHour}:${this.scheduledMinute}`,
                'YYYY-MM-DD HH:mm'
            ).format('YYYY-MM-DD HH:mm:ss');
        },
    },
    methods: {
        handleModalHidden() {
            // This method will be called whenever the modal is hidden
            if (!this.saveProgress) {
                this.contentCalendarStore.$reset();
            }
            // Reset the flag
            this.saveProgress = false;
        },
        closeModal() {
            this.saveProgress = true;
            this.modal.hide();
            this.isRescheduling = false;

            if (this.isDuplicating) {
                // eslint-disable-next-line no-undef
                emitter.emit('open-content-calendar-content-edit-modal');
            } else if (this.contentCalendar.id) {
                // eslint-disable-next-line no-undef
                emitter.emit('open-content-calendar-event-preview-modal');
            } else {
                // eslint-disable-next-line no-undef
                emitter.emit('open-content-calendar-content-preview-modal');
            }
        },
        createOrUpdateCalendar() {
            if (this.contentCalendar.id) {
                this.updateCalendar();
            } else {
                this.addToCalendar();
            }
            router.visit('/content-planner');
            this.modal.hide();
        },
        updateCalendar() {
            let vm = this;

            if (!this.contentCalendar) {
                return false;
            }

            this.contentCalendar.scheduled_at = this.formatedScheduledDate;
            vm.loading = true;
            // eslint-disable-next-line no-undef
            axios
                // eslint-disable-next-line no-undef
                .put(
                    // eslint-disable-next-line no-undef
                    route('content-calendar.update', this.contentCalendar.id),
                    this.contentCalendar
                )
                .then((response) => {
                    const responseData = response.data.data;
                    const formattedDate = moment(
                        responseData.created_at
                    ).format('YYYY-MM-DD HH:mm:ss');
                    this.contentCalendar = {
                        attachments: responseData.attachments || [],
                        content: responseData.content,
                        created: formattedDate,
                        icon: null,
                        id: responseData.id,
                        title: responseData.title,
                        scheduled_at: responseData.scheduled_at,
                        social_media: responseData.social_media,
                        status: responseData.status,
                    };
                    vm.toast.success(response.data.message);
                    vm.saveProgress = true;
                    vm.modal.hide();
                    // eslint-disable-next-line no-undef
                    emitter.emit('open-content-calendar-event-preview-modal');
                    // eslint-disable-next-line no-undef
                    emitter.emit('content-calendar-reload-event-data');
                })
                .catch((error) => {
                    vm.toast.error(error.response?.data?.message);
                })
                .then(() => {
                    vm.loading = false;
                });
        },
        addToCalendar() {
            let vm = this;

            if (!this.contentCalendar) {
                return false;
            }

            const payload = {
                social_media: this.contentCalendar?.social_media,
                title: this.contentCalendar?.title,
                content: this.contentCalendar?.content,
                scheduled_at: this.formatedScheduledDate,
                attachments: this.contentCalendar?.attachments,
                status: this.contentCalendar?.status,
            };
            vm.loading = true;
            // eslint-disable-next-line no-undef
            axios
                // eslint-disable-next-line no-undef
                .post(route('content-calendar.store'), payload)
                .then(() => {
                    vm.contentCalendarStore.$reset();
                    vm.toast.success(vm.__('Saved successfully'));
                    // eslint-disable-next-line no-undef
                    emitter.emit('close-add-new-content-calendar-modal');
                    // eslint-disable-next-line no-undef
                    emitter.emit(
                        'close-content-calendar-content-preview-modal'
                    );
                    // eslint-disable-next-line no-undef
                    emitter.emit(
                        'close-content-calendar-output-selection-modal'
                    );
                    // eslint-disable-next-line no-undef
                    emitter.emit('close-content-calendar-time-selection-modal');
                    // eslint-disable-next-line no-undef
                    emitter.emit('content-calendar-reload-event-data');
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        const errorMessages = Object.values(
                            error.response.data.errors
                        ).flat();
                        const formattedErrors = errorMessages
                            .map((msg) => `• ${msg}`)
                            .join('\n');
                        vm.toast.error(formattedErrors);
                    } else {
                        vm.toast.error(error.response.data.message);
                    }
                })
                .then(() => {
                    vm.loading = false;
                });
        },
        updateScheduledDateTime(newScheduledAt) {
            if (!newScheduledAt || !this.$el) return;

            const scheduledMoment = moment(newScheduledAt);

            // Use Vue's nextTick to ensure the DOM has updated
            this.$nextTick(() => {
                // Update scheduledDate using CalendarDate
                this.scheduledDate = new CalendarDate(
                    scheduledMoment.year(),
                    scheduledMoment.month() + 1,
                    scheduledMoment.date()
                );

                // Update scheduledHour (24-hour format)
                this.scheduledHour = scheduledMoment.format('HH');

                // Update scheduledMinute
                this.scheduledMinute = scheduledMoment.format('mm');

                // TODO: enable later based on location
                // Update scheduledTimePeriod
                // this.scheduledTimePeriod = scheduledMoment
                //     .format('a')
                //     .toLowerCase();
            });
        },
    },
});
</script>

<style lang="scss">
#kt_modal_time_selection {
    .modal-dialog {
        .modal-header {
            z-index: 1;
        }

        .modal-body {
            .calendar-time-selection {
                .calendar-time-selection-period {
                    width: 70px;
                    height: 51px;
                    border: 1px solid #e4e4e4;
                    border-radius: 8px;

                    .calendar-time-selection-period-item {
                        position: relative;
                        height: 25px;

                        input {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                            cursor: pointer;
                        }

                        &.active {
                            background-color: #f1faff;
                            color: #00a3ff;
                            font-weight: 600;
                        }

                        label {
                            position: relative;
                            font-size: 12px;
                            line-height: 1.5;
                            cursor: pointer;
                        }
                    }
                }
                input[type='number'] {
                    height: 50px;
                    width: 100%;
                    border-radius: 8px;
                    border: 1px solid #e4e4e4;
                    padding: 10px 14px;
                    font-size: 16px;
                }

                label {
                    display: block;
                    position: absolute;
                }
            }
        }
    }

    .calendar-cell {
        padding: 0 !important;

        > div {
            padding: 6px 8px !important;
            border-radius: 4px;
        }

        div[data-selected='true'] {
            background-color: #0186d2;
            color: #fff;
        }
    }
}
</style>

<template>
    <file-pond
        name="file"
        ref="pond"
        class-name="border-dashed border-1 border-primary bg-white rounded file-upload-container cursor-pointer overflow-hidden"
        :label-idle="dropLabel"
        :label-file-loading="__('Loading')"
        :label-file-processing-complete="__('Upload complete')"
        :label-file-processing-aborted="__('Upload cancelled')"
        :label-file-processing-error="__('Error during upload')"
        :label-invalid-field="__('Field contains invalid files')"
        :label-ta-to-cancel="__('tap to cancel')"
        :label-ta-to-retry="__('tap to retry')"
        :label-ta-to-undo="__('tap to undo')"
        allow-multiple="false"
        max-files="1"
        accepted-file-types="text/csv,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/xml, application/xml"
        credits="false"
        v-bind:files="files"
        @error="handleFileError"
        @addfilestart="handleFileStart"
        @processfile="handleFileFinish"
    />
</template>

<script>
import vueFilePond, { setOptions } from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import 'filepond/dist/filepond.min.css';
import { usePage } from '@inertiajs/vue3';

const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
    name: 'BulkProcessingFileUpload',
    components: {
        FilePond,
    },

    setup() {
        const page = usePage();

        return {
            page,
        };
    },

    data() {
        return {
            files: [],
            dropLabel: `<div class="drop-section cursor-pointer">
                <div class="d-flex align-items-center">
                 <i class="bi bi-file-earmark-plus fs-1 text-primary"></i>
                    <span class="ms-2 text-primary fs-5 fw-bold">${this.__(
                        'Upload file'
                    )}</span>
                </div>
            </div>`,
        };
    },

    mounted() {
        const vm = this;
        this.setFilePondOptions();

        // eslint-disable-next-line no-undef
        emitter.on('retry-bulk-processing-file-upload', () => {
            const retryButtonElement = document.querySelector(
                '.filepond--action-retry-item-processing'
            );

            if (retryButtonElement) {
                retryButtonElement.click();
                vm.handleFileStart();
            }
        });
    },

    beforeUnmount() {
        // eslint-disable-next-line no-undef
        emitter.off('retry-bulk-processing-file-upload');
    },

    computed: {
        csrfToken() {
            try {
                return this.page.props.csrf_token;
            } catch (error) {
                console.error('CSRF token issue in file upload');
            }
            return '';
        },
    },

    methods: {
        setFilePondOptions() {
            setOptions({
                server: {
                    url: '/filepond/api',
                    process: '/process',
                    revert: '/process',
                    patch: '?patch=',
                    headers: {
                        'X-CSRF-TOKEN': this.csrfToken,
                    },
                },
            });
        },
        handleFileError() {
            this.$emit('file-upload-error');
        },
        handleFileStart() {
            this.$emit('file-upload-started');
        },
        handleFileFinish() {
            this.$emit('file-upload-finished', this.$refs.pond.getFile());
        },
    },
};
</script>

<style lang="scss">
.filepond--drop-label {
    background-color: #fff;
}

.filepond--root.file-upload-container {
    min-height: 72px;
    .bg-white {
        background: #fff !important;
    }
    .filepond--list-scroller {
        height: 100%;
    }

    .filepond--file {
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
    }
}
.filepond--root .filepond--drop-label {
    min-height: 72px;
}
</style>

<template>
    <div
        class="modal fade"
        id="kt_modal_add_new_content"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered">
            <!--begin::Modal content-->
            <div class="modal-content p-5">
                <!--begin::Modal header-->
                <div class="modal-header bg-white p-0 pb-5 mb-5">
                    <!--begin::Heading-->
                    <h2>{{ __('Add new content') }}</h2>
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body p-0 m-0">
                    <div
                        class="radio-option"
                        :class="{ selected: contentType === 'new' }"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_ai_assistant"
                        @click="next('new')"
                    >
                        <input
                            type="radio"
                            v-model="contentType"
                            value="new"
                            class="stretched-link"
                        />
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M33.85 7.80156V16.0015L24.05 21.6015V10.2016L31.05 6.20155C32.25 5.60155 33.85 6.40156 33.85 7.80156ZM5.05 27.0015L12.05 31.0015L21.85 25.4015L12.05 19.8016L5.05 23.8016C3.65 24.6016 3.65 26.4015 5.05 27.0015ZM36.05 39.4015V31.2016L26.25 25.6015V37.0015L33.25 41.0015C34.45 41.6015 36.05 40.8015 36.05 39.4015Z"
                                fill="#00A3FF"
                            />
                            <path
                                opacity="0.3"
                                d="M17.0508 6.20155L24.0508 10.2016V21.6015L14.2508 16.0015V7.80156C14.2508 6.40156 15.8508 5.60155 17.0508 6.20155ZM14.8508 41.0015L21.8508 37.0015V25.6015L12.0508 31.2016V39.4015C12.0508 40.8015 13.6508 41.6015 14.8508 41.0015ZM43.0508 23.8016L36.0508 19.8016L26.2508 25.4015L36.0508 31.0015L43.0508 27.0015C44.4508 26.4015 44.4508 24.6016 43.0508 23.8016Z"
                                fill="#00A3FF"
                            />
                        </svg>

                        <div class="ms-5">
                            <strong class="fs-5">
                                {{ __('Generate New Content') }}
                            </strong>
                            <p>
                                {{
                                    __(
                                        'Use AI assistance to generate new contents.'
                                    )
                                }}
                            </p>
                        </div>
                    </div>

                    <div
                        class="radio-option"
                        :class="{ selected: contentType === 'existing' }"
                        @click="next('existing')"
                    >
                        <input
                            type="radio"
                            v-model="contentType"
                            value="existing"
                            class="stretched-link"
                        />
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.3"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M25.2188 28L31.1007 20H26.6893V12L17.8664 24H22.2778V28H11.9109C10.8146 28 9.92578 27.1046 9.92578 26V6C9.92578 4.89543 10.8146 4 11.9109 4H35.7327C36.8291 4 37.7179 4.89543 37.7179 6V26C37.7179 27.1046 36.8291 28 35.7327 28H25.2188Z"
                                fill="#00A3FF"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.4646 21.8288L17.8679 24.0004H22.2794V32.0004L31.1022 20.0004H30.695L40.116 13.1454C40.5604 12.8221 41.1808 12.9229 41.5018 13.3706C41.624 13.5411 41.6897 13.7459 41.6897 13.9561V34.0004C41.6897 36.2095 39.9121 38.0004 37.7194 38.0004H9.92733C7.73459 38.0004 5.95703 36.2095 5.95703 34.0004V13.9561C5.95703 13.4038 6.40142 12.9561 6.94961 12.9561C7.15826 12.9561 7.3616 13.0224 7.53075 13.1454L19.4646 21.8288Z"
                                fill="#00A3FF"
                            />
                        </svg>

                        <div class="ms-5">
                            <strong class="fs-5">
                                {{ __('From Current Content') }}
                            </strong>
                            <p>
                                {{ __('Use the content you already created') }}
                            </p>
                        </div>
                    </div>
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';
import { router } from '@inertiajs/vue3';

export default defineComponent({
    data() {
        return {
            modal: {},
            contentType: null,
            saveProgress: false,
        };
    },
    mounted() {
        let vm = this;

        this.modal = new Modal(
            document.getElementById('kt_modal_add_new_content')
        );

        // Add event listener for modal hidden event
        document
            .getElementById('kt_modal_add_new_content')
            .addEventListener('hidden.bs.modal', this.handleModalHidden);

        // eslint-disable-next-line no-undef
        emitter.on('open-add-new-content-calendar-modal', () =>
            vm.modal.show()
        );
        // eslint-disable-next-line no-undef
        emitter.on('close-add-new-content-calendar-modal', () =>
            vm.modal.hide()
        );
    },
    beforeUnmount() {
        // Remove the event listener when component is unmounted
        document
            .getElementById('kt_modal_add_new_content')
            .removeEventListener('hidden.bs.modal', this.handleModalHidden);

        // eslint-disable-next-line no-undef
        emitter.off('open-add-new-content-calendar-modal');
        // eslint-disable-next-line no-undef
        emitter.off('close-add-new-content-calendar-modal');
    },
    methods: {
        handleModalHidden() {
            // This method will be called whenever the modal is hidden
            if (!this.saveProgress) {
                this.contentCalendarStore?.$reset();
            }
            // Reset the flag
            this.saveProgress = false;
        },
        closeModal() {
            this.saveProgress = true;
            this.modal.hide();
            this.contentType = '';
        },
        next(contentType) {
            this.closeModal();
            if (contentType === 'existing') {
                // eslint-disable-next-line no-undef
                emitter.emit('open-content-calendar-output-selection-modal');
            }
        },
    },
});
</script>

<style lang="scss">
#kt_modal_add_new_content {
    .modal-content {
        .modal-header {
            z-index: 1;
        }

        .modal-body {
            .radio-option {
                position: relative;
                display: flex;
                border: 1px dashed #b5b5c3;
                border-radius: 8px;
                background-color: #fbfcfd;
                margin-bottom: 15px;
                padding: 20px 30px;
                transition: all 0.3s ease;

                svg {
                    transition: all 0.3s ease;
                    filter: grayscale(100%);
                }

                &.selected,
                &:hover {
                    border-color: #00a3ff;
                    background-color: #f1faff;

                    svg {
                        filter: grayscale(0%);
                    }
                }

                input {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>

<template>
    <div
        class="modal fade"
        id="kt_modal_calendar_image_crop"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <!--begin::Modal content-->
            <div class="modal-content p-4">
                <!--begin::Modal header-->
                <div class="modal-header bg-white p-0 pb-5 mb-5">
                    <!--begin::Heading-->
                    <h2>{{ __('Crop Image') }}</h2>

                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body p-0 m-0">
                    <div>
                        <div class="edit-post-content">
                            <div class="d-flex">
                                <div class="w-25 me-5">
                                    <label
                                        class="form-label"
                                        for="socialMediaType"
                                    >
                                        {{ __('Type') }}
                                    </label>
                                    <select
                                        id="socialMediaType"
                                        class="form-select"
                                        v-model="selectedAspectRatio"
                                        @change="
                                            reInitilizeCropper(
                                                $event.target.value
                                            )
                                        "
                                    >
                                        <option disabled value="">
                                            {{ __('Select an option') }}
                                        </option>
                                        <option
                                            :value="aspectRatio.value"
                                            v-for="aspectRatio in availableAspectRatios"
                                            :key="aspectRatio.value"
                                        >
                                            {{ __(selectedSocialMedia.name) }}
                                            {{ __(aspectRatio.label) }}
                                            ({{ aspectRatio.value }})
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div
                                class="mt-8 w-full h-[calc(92vh-19rem)] overflow-auto"
                            >
                                <img
                                    ref="imageCropper"
                                    class="mx-auto w-auto h-full"
                                    :src="imageUrl"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Modal body-->
                <div class="modal-footer border-0">
                    <div class="d-flex justify-content-end m-0">
                        <button
                            class="btn btn-secondary me-3"
                            @click="closeModal"
                        >
                            {{ __('Discard Cropping') }}
                        </button>
                        <button class="btn btn-secondary" @click="saveCropping">
                            {{ __('Save Cropping') }}
                        </button>
                    </div>
                </div>
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';
import { useContentCalendarStore } from '@/Stores/ContentCalendarStore';
import { mapState, mapWritableState } from 'pinia';
import { useToast } from 'vue-toastification';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

export default defineComponent({
    props: {
        imageUrl: {
            type: String,
            require: true,
        },
    },
    setup() {
        const contentCalendarStore = useContentCalendarStore();
        const toast = useToast();

        return {
            contentCalendarStore,
            toast,
        };
    },
    data() {
        return {
            modal: {},
            cropper: null,
            selectedAspectRatio: '',
        };
    },
    computed: {
        ...mapState(useContentCalendarStore, ['isEditing', 'contentStatuses']),
        ...mapWritableState(useContentCalendarStore, [
            'contentCalendar',
            'isDuplicating',
            'socialMedias',
        ]),
        selectedSocialMedia() {
            const selectedSocialMedia = this.contentCalendar.social_media;

            return (
                this.socialMedias.find(
                    (socialMedia) => socialMedia.value === selectedSocialMedia
                ) ?? null
            );
        },
        availableAspectRatios() {
            const imageAspectRatios =
                this.selectedSocialMedia?.imageAspectRatios;
            if (
                Array.isArray(imageAspectRatios) &&
                imageAspectRatios.length > 0
            ) {
                return imageAspectRatios;
            }

            return [
                {
                    label: 'Square',
                    value: '1:1',
                },
            ];
        },
    },
    mounted() {
        let vm = this;

        this.modal = new Modal(
            document.getElementById('kt_modal_calendar_image_crop')
        );

        // Add event listener for modal hidden event
        document
            .getElementById('kt_modal_calendar_image_crop')
            .addEventListener('hidden.bs.modal', this.handleModalHidden);

        // eslint-disable-next-line no-undef
        emitter.on('open-content-calendar-image-crop-modal', () => {
            vm.openModal();
            setTimeout(() => {
                this.selectedAspectRatio =
                    this.availableAspectRatios[0]?.value || '1:1';
                this.reInitilizeCropper(this.selectedAspectRatio);
            }, 300);
        });
        // eslint-disable-next-line no-undef
        emitter.on('close-content-calendar-image-crop-modal', () =>
            vm.closeModal()
        );

        this.selectedAspectRatio =
            this.availableAspectRatios[0]?.value || '1:1';
        this.initilizeImageCropper(this.selectedAspectRatio);
    },
    beforeUnmount() {
        // Remove the event listener when component is unmounted
        document
            .getElementById('kt_modal_calendar_image_crop')
            .removeEventListener('hidden.bs.modal', this.closeModal);

        // eslint-disable-next-line no-undef
        emitter.off('open-content-calendar-image-crop-modal');
        // eslint-disable-next-line no-undef
        emitter.off('close-content-calendar-image-crop-modal');

        this.destroyCropper();
    },
    methods: {
        openModal() {
            this.modal.show();
        },
        closeModal() {
            this.modal.hide();
        },
        initilizeImageCropper(aspectRatio = '1:1') {
            const image = this.$refs.imageCropper;
            aspectRatio = this.getAspectRatio(aspectRatio); // Get the aspect ratio based on the selected option

            this.cropper = new Cropper(image, {
                ready: function () {
                    const cropper = this.cropper;
                    const containerData = cropper.getContainerData();
                    const cropBoxData = cropper.getCropBoxData();
                    let newCropBoxWidth;

                    // Adjust the crop box to maintain the selected aspect ratio
                    newCropBoxWidth = cropBoxData.height * aspectRatio;

                    cropper.setCropBoxData({
                        left: (containerData.width - newCropBoxWidth) / 2,
                        width: newCropBoxWidth,
                    });
                },
                zoomOnWheel: false,

                cropmove: function () {
                    const cropper = this.cropper;
                    const cropBoxData = cropper.getCropBoxData();

                    // Enforce the selected aspect ratio during resizing
                    cropper.setCropBoxData({
                        width: cropBoxData.height * aspectRatio,
                    });
                },
            });
        },
        destroyCropper() {
            this.cropper.destroy();
        },
        reInitilizeCropper(aspectRatio) {
            // Reinitialize the cropper with the new aspect ratio
            if (this.cropper) {
                this.destroyCropper(); // Destroy the existing cropper instance
            }
            this.initilizeImageCropper(aspectRatio); // Call a method to initialize the cropper with the new aspect ratio
        },
        getAspectRatio(selectedRatio) {
            const aspectRatios = {
                '1:1': 1, //1/1
                '4:5': 0.8, //4/5
                '9:16': 0.5625, //9/16
                '16:9': 1.7778, //16/9
                '1.91:1': 1.91, //1.91/1
                '2:3': 0.6667, //2/3
            };
            return aspectRatios[selectedRatio] || 1; // Default to 1 if not found
        },
        saveCropping() {
            // Get the cropped canvas
            const canvas = this.cropper.getCroppedCanvas();
            this.$emit('save-cropping', canvas);
        },
    },
});
</script>

<style lang="scss">
#kt_modal_calendar_image_crop {
    .modal-dialog {
        .modal-header {
            z-index: 1;
        }

        .modal-body {
            .edit-post-content {
                .filepond-edit-post {
                    height: 200px;
                    background-color: #fafbfb;
                    cursor: pointer;

                    .filepond--drop-label {
                        height: 200px;

                        label {
                            font-size: 14px;
                            color: #0186d2;
                        }
                    }

                    .drop-section {
                        i {
                            color: #0186d2;
                            bottom: -2px;
                        }

                        p {
                            font-size: 14px;
                            color: #45577b;

                            span {
                                color: #0186d2;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

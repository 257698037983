<template>
    <!--begin::Modal - New Project-->
    <div
        class="modal fade"
        id="kt_modal_ai_assistant"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-fullscreen">
            <!--begin::Modal content-->
            <div class="modal-content">
                <!--begin::Modal header-->
                <div class="modal-header bg-white">
                    <!--begin::Heading-->
                    <h2>{{ __('AI Assistant') }}</h2>
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body m-5 d-flex px-0 mx-0">
                    <div class="d-flex">
                        <Prompt
                            :user="user"
                            :ai-assistant-templates="aiAssistantTemplates"
                            :ai-assistant-template-uuid="uuid"
                            :prompt-title="promptTitle"
                            :loading="loading"
                            :fields="fields"
                            @change-ai-assistant-template="
                                onChangeAiAssistantTemplate
                            "
                            @prompt-change="onPromptChange"
                            @creativity-change="
                                (ev) => (options.creativity = ev)
                            "
                            @outputs-change="(ev) => (options.outputs = ev)"
                            @tone-of-voice-change="
                                (ev) => (options.toneOfVoice = ev)
                            "
                            @target-audience-change="
                                (ev) => (options.targetAudience = ev)
                            "
                            @with-emojis-change="
                                (ev) => (options.withEmojis = ev)
                            "
                            @language-change="onLanguageChange"
                            @formality-change="onFormalityChange"
                            @prompt-settings-accordion-open-change="
                                onPromptSettingsAccordionOpenChange
                            "
                            @fetched-prompt-settings="fetchedPromptSettings"
                            @compose="compose"
                        >
                        </Prompt>
                    </div>
                    <Result
                        :result-title="resultTitle"
                        :prompt-title="promptTitleEnglish"
                        :choices="choices"
                        :in-modal="true"
                    >
                    </Result>
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import {router as Inertia} from '@inertiajs/vue3';
import NProgress from 'nprogress';
import Multiselect from '@vueform/multiselect';
import Language from '@/Layouts/SettingElements/Language.vue';
import Prompt from '@/Layouts/AiAssistant/Partials/Prompt.vue';
import Result from '@/Layouts/AiAssistant/Partials/Result.vue';
import { debounce } from 'lodash';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { Modal } from 'bootstrap';
import trialLimitExhausted from '@/Mixins/trialLimitExhausted';
import { useUserStore } from '@/Stores/UserStore';
import { useTeamStore } from '@/Stores/TeamStore';
import { useSubscriptionStore } from '@/Stores/SubscriptionStore';

export default defineComponent({
    mixins: [trialLimitExhausted],
    setup() {
        // Get toast interface
        const toast = useToast();

        // Get user store
        const userStore = useUserStore();
        // Get team store
        const teamStore = useTeamStore();
        // Get subscription store
        const subscriptionStore = useSubscriptionStore();

        // Make it available inside methods
        return {
            toast,
            userStore,
            teamStore,
            subscriptionStore,
        };
    },

    props: {
        user: Object,
    },
    components: {
        Multiselect,
        NProgress,
        Prompt,
        Result,
        Language,
    },
    data() {
        let projectSettings = this.user?.current_project?.prompt_settings || {};
        let userSettings = this.user.user_info.prompt_settings || {};

        return {
            uuid: '',
            promptTitle: this.__('Text Body'),
            // to detect social media type based on it
            promptTitleEnglish: '',
            promptInfo: '',
            resultTitle: this.__('Choose from the results'),
            aiAssistantTemplates: {},
            description: '',
            name: '',
            fields: {},
            prompts: {
                description: ''
            },
            options: {
                creativity: projectSettings.creativity || userSettings.creativity || 0.75,
                outputs: projectSettings.outputs || userSettings.outputs || 3,
                toneOfVoice: projectSettings.toneOfVoice || userSettings.toneOfVoice || '',
                targetAudience: projectSettings.targetAudience || userSettings.targetAudience || '',
                withEmojis: projectSettings.withEmojis || userSettings.withEmojis || false,
                targetLang: projectSettings.targetLang || userSettings.targetLang || 'en',
                formality: projectSettings.formality || userSettings.formality || 'default',
            },
            promptSettingsAccordionOpen:
                this.user.user_info.prompt_settings &&
                this.user.user_info.prompt_settings
                    .promptSettingsAccordionOpen !== undefined
                    ? this.user.user_info.prompt_settings
                          .promptSettingsAccordionOpen
                    : true,
            loading: false,
            choices: [],
            modal: {},
            document: {
                id: '',
            },
        };
    },
    mounted() {
        this.modal = new Modal(
            document.getElementById('kt_modal_ai_assistant'),
            {
                backdrop: this.userHasCurrentProject ? 'dynamic' : 'static',
            }
        );
        this.fetchAiAssistantTemplates();

        let vm = this;

        emitter.on('document-text-insert', () => {
            this.modal.hide();
        });
        emitter.on('document-loaded', function (document) {
            vm.document.id = document.id;
        });

        this.userStore.setUser(this.user);
        this.subscriptionStore.setPlans(this.getPlans() || {});
    },
    methods: {
        fetchAiAssistantTemplates() {
            let vm = this;
            axios.get('/api/ai-assistant-templates').then((response) => {
                // reset prompt values but keep description
                let description = vm.prompts['description']
                    ? vm.prompts['description']
                    : '';
                vm.prompts = {};
                vm.prompts['description'] = description;

                response.data.forEach(function (template) {
                    vm.aiAssistantTemplates[template.uuid] = template;
                });
                if (vm.uuid) {
                    vm.promptTitle =
                        vm.aiAssistantTemplates[vm.uuid].name[
                            vm.$page.props.locale
                        ];
                    vm.promptTitleEnglish =
                        vm.aiAssistantTemplates[vm.uuid].name['en'];
                    vm.resultTitle = vm.__('Choose from the results');

                    vm.name = vm.aiAssistantTemplates[vm.uuid].name;
                    vm.description =
                        vm.aiAssistantTemplates[vm.uuid].description;
                    vm.fields = vm.aiAssistantTemplates[vm.uuid].fields;
                    vm.aiAssistantTemplates[vm.uuid].fields.forEach(
                        function (field) {
                            vm.prompts[field.layout] =
                                vm.prompts[field.layout] !== undefined
                                    ? vm.prompts[field.layout]
                                    : '';
                        }
                    );
                    vm.aiAssistantTemplates[vm.uuid].options.forEach(
                        function (option) {
                            Object.assign(vm.options, option);
                        }
                    );

                    emitter.emit(
                        'change-template-name',
                        this.aiAssistantTemplates[this.uuid].name[
                            this.$page.props.locale
                        ]
                    );
                }
            });
        },

        onChangeAiAssistantTemplate(value) {
            //window.history.pushState({}, document.title, window.location.origin + '/ai-assistant/' + value);
            this.uuid = value;
            let prompts = this.prompts;
            this.fetchAiAssistantTemplates();
            this.choices = [];
        },

        onPromptChange(ev) {
            this.prompts[ev.component] = ev.value || '';
        },

        onLanguageChange(ev) {
            this.options.targetLang = ev;
        },

        onFormalityChange(ev) {
            this.options.formality = ev;
        },

        onPromptSettingsAccordionOpenChange(ev) {
            this.promptSettingsAccordionOpen = ev;
        },

        changePromptSettings() {
            let vm = this;
            let documentUuid = this.$page?.props?.document?.uuid;

            if (!documentUuid) {
                return;
            }

            let payLoad = {
                prompt_settings: this.promptSettings,
            };

            axios
                .post(`/api/document/${documentUuid}/prompt-settings`, payLoad)
                .then((response) => {
                    //vm.toast.success(vm.__('Prompt updated'));
                })
                .catch((error) => {
                    //vm.toast.error(vm.__('Some error occured'));
                });
        },

        fetchedPromptSettings(promptSettings) {
            this.options = { ...this.options, ...promptSettings };
        },

        compose() {
            NProgress.start();
            this.loading = true;
            let vm = this;
            if (this.teamStore.hasCharacterCredits || this.subscriptionStore.hasUnlimitedUsage) {
                let data = {
                    uuid: this.uuid,
                    prompts: {
                        description: this.prompts.description || ''
                    },
                    options: {
                        creativity: this.options.creativity || 0.75,
                        outputs: this.options.outputs || 1,
                        toneOfVoice: this.options.toneOfVoice || '',
                        targetAudience: this.options.targetAudience || '',
                        withEmojis: this.options.withEmojis || false,
                        targetLang: this.options.targetLang || 'en',
                        formality: this.options.formality || 'default'
                    },
                    lang: this.options.targetLang || 'en',
                    formality: this.options.formality || 'default',
                    documentId: this.document.id || ''
                };

                // Fake respond progress
                setTimeout(() => NProgress.set(0.4), 500);
                setTimeout(() => NProgress.inc(), 1500);
                setTimeout(() => NProgress.inc(), 3000);

                axios
                    .post('/api/ai-assistant/compose', data)
                    .then((response) => {
                        this.loading = false;
                        this.choices = response.data.choices;
                        Inertia.reload({ only: ['user'] });
                        NProgress.done();
                    })
                    .catch(function (error) {
                        vm.toast.error(error.response.data.message);
                        if (error.response.data.error_code == 'trial_credit_limit_exhausted') {
                            vm.closeModal();
                            vm.showTrialLimitExhaustedPopUp();
                        }
                        NProgress.done();
                        vm.loading = false;
                    });
            } else {
                NProgress.done();
                this.toast.error(this.__('Your credits are over'));
                this.showTrialLimitExhaustedPopUp();
            }
        },
        closeModal() {
            this.modal.hide();
        },
    },
    watch: {
        promptSettings: debounce(function () {
            this.changePromptSettings();
        }, 500),
    },
    computed: {
        promptSettings() {
            return {
                targetLang: this.options.targetLang,
                formality: this.options.formality,
                promptSettingsAccordionOpen: this.promptSettingsAccordionOpen,
                creativity: this.options.creativity,
                outputs: this.options.outputs,
                toneOfVoice: this.options.toneOfVoice,
                targetAudience: this.options.targetAudience,
            };
        },
    },
});
</script>

<style lang="scss">
#kt_modal_ai_assistant .modal-dialog .modal-header {
    z-index: 1;
}
</style>

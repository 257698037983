<template>
    <div
        class="modal fade"
        id="kt_modal_output_selection"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <!--begin::Modal content-->
            <div class="modal-content p-5">
                <!--begin::Modal header-->
                <div class="modal-header bg-white p-0 pb-5 mb-5">
                    <!--begin::Heading-->
                    <h2>{{ __('Assign outputs to calendar') }}</h2>
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body p-0 m-0">
                    <div
                        class="modal-action d-flex justify-content-between mb-5"
                    >
                        <div
                            class="search-wrapper position-relative me-5 w-100"
                        >
                            <i
                                class="bi bi-search position-absolute top-50 translate-middle-y me-2"
                            ></i>
                            <input
                                type="text"
                                v-model="search"
                                @input="searchResult"
                                :placeholder="__('Search')"
                                class="pe-5"
                            />
                        </div>
                        <select
                            v-model="selectedTemplate"
                            @change="filterOutputByAiAssistantTemplate"
                        >
                            <option value="">
                                {{ __('Category') }}
                            </option>
                            <option
                                v-for="aiAssistantTemplate in aiAssistantTemplates"
                                :key="aiAssistantTemplate.uuid"
                                :value="aiAssistantTemplate.uuid"
                            >
                                {{ aiAssistantTemplate.name }}
                            </option>
                        </select>
                    </div>
                    <div class="content-calendar-output-selection">
                        <div
                            class="content-calendar-output-selection-item mb-3"
                            v-for="aiAssistantOutput in aiAssistantOutputs"
                            :key="aiAssistantOutput.id"
                            @click="toggleSelection($event, aiAssistantOutput)"
                            ref="outputItems"
                        >
                            <div class="d-flex">
                                <img :src="aiAssistantOutput.icon" />
                                <div class="ms-3">
                                    <h4>
                                        {{ aiAssistantOutput.title }}
                                    </h4>
                                    <p class="mb-0">
                                        {{ aiAssistantOutput.short_content }}
                                    </p>
                                </div>
                            </div>
                            <span class="text-muted d-flex justify-content-end"
                                >{{ __('Created') }}:
                                {{ aiAssistantOutput.created }}
                            </span>
                        </div>
                    </div>
                    <div
                        class="d-flex justify-content-end border-top pt-5 mt-5"
                    >
                        <button
                            class="btn btn-secondary me-3"
                            @click="closeModal"
                        >
                            {{ __('Cancel') }}
                        </button>
                        <button
                            class="btn btn-primary"
                            :class="{
                                disabled: inActiveNextButton,
                            }"
                            @click="add"
                        >
                            {{ __('Add') }}
                        </button>
                    </div>
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';
import { debounce } from 'lodash';
import moment from 'moment';
import { useContentCalendarStore } from '@/Stores/ContentCalendarStore';
import { mapWritableState } from 'pinia';
import markdownMixin from '@/Mixins/markdownMixin';

export default defineComponent({
    mixins: [markdownMixin],
    setup() {
        const contentCalendarStore = useContentCalendarStore();

        return {
            contentCalendarStore,
        };
    },
    data() {
        return {
            aiAssistantTemplates: [],
            aiAssistantOutputs: [],
            isOutputSelected: false,
            loading: false,
            modal: {},
            outputFilters: {},
            search: '',
            selectedTemplate: '',
            saveProgress: false,
            selectedOutputIds: [],
            selectedOutputId: null,
        };
    },
    mounted() {
        let vm = this;

        this.modal = new Modal(
            document.getElementById('kt_modal_output_selection')
        );

        // Add event listener for modal hidden event
        document
            .getElementById('kt_modal_output_selection')
            .addEventListener('hidden.bs.modal', this.handleModalHidden);

        // eslint-disable-next-line no-undef
        emitter.on('open-content-calendar-output-selection-modal', () =>
            vm.modal.show()
        );
        // eslint-disable-next-line no-undef
        emitter.on('close-content-calendar-output-selection-modal', () =>
            vm.modal.hide()
        );

        this.fetchAiAssistantTemplates();
        this.fetchAiAssistanceOutputCreations();
    },
    beforeUnmount() {
        // Remove the event listener when component is unmounted
        document
            .getElementById('kt_modal_output_selection')
            .removeEventListener('hidden.bs.modal', this.handleModalHidden);

        // eslint-disable-next-line no-undef
        emitter.off('open-content-calendar-output-selection-modal');
        // eslint-disable-next-line no-undef
        emitter.off('close-content-calendar-output-selection-modal');
    },
    computed: {
        ...mapWritableState(useContentCalendarStore, ['contentCalendar']),
        userLocale() {
            return this.$attrs?.user?.user_info?.language || 'en';
        },
        inActiveNextButton() {
            return !this.isOutputSelected;
        },
    },
    methods: {
        fetchAiAssistantTemplates() {
            let vm = this;
            vm.loading = true;
            // eslint-disable-next-line no-undef
            axios
                .get(
                    // eslint-disable-next-line no-undef
                    route('content-calendar.get-ai-assistance-templates')
                )
                .then((response) => {
                    const templates = response.data.templates || [];

                    vm.aiAssistantTemplates = templates
                        ?.map((template) => ({
                            name: template.name[vm.userLocale],
                            uuid: template.uuid,
                        }))
                        .sort((a, b) => a.name.localeCompare(b.name));
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    vm.loading = false;
                });
        },
        fetchAiAssistanceOutputCreations(filters = {}) {
            let vm = this;
            vm.loading = true;
            // eslint-disable-next-line no-undef
            axios
                .get(
                    // eslint-disable-next-line no-undef
                    route(
                        'content-calendar.get-ai-assistance-output-creations',
                        filters
                    )
                )
                .then((response) => {
                    const aiAssistantOutputs = response.data.outputs || [];

                    vm.aiAssistantOutputs = aiAssistantOutputs.map(
                        (aiAssistantOutput) => {
                            // Convert markdown to plain text
                            const content = vm.renderPlainText(
                                aiAssistantOutput.output
                            );

                            return {
                                content,
                                created: moment(
                                    aiAssistantOutput.created_at
                                ).format('MM/DD/YYYY'),
                                icon: aiAssistantOutput.template_icon_path,
                                short_content: content.substring(0, 30) + '...',
                                title: aiAssistantOutput?.name,
                                social_media: aiAssistantOutput.social_media,
                            };
                        }
                    );
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    vm.loading = false;
                });
        },
        searchResult: debounce(function () {
            this.isOutputSelected = false;
            this.outputFilters = { ...this.outputFilters, search: this.search };
            this.fetchAiAssistanceOutputCreations(this.outputFilters);
        }, 500),
        filterOutputByAiAssistantTemplate() {
            this.isOutputSelected = false;
            this.outputFilters = {
                ...this.outputFilters,
                template_uuid: this.selectedTemplate,
            };
            this.fetchAiAssistanceOutputCreations(this.outputFilters);
        },
        handleModalHidden() {
            // This method will be called whenever the modal is hidden
            if (!this.saveProgress) {
                this.isOutputSelected = false;
                this.contentCalendarStore.$reset();
            }
            // Reset the flag
            this.saveProgress = false;
        },
        closeModal() {
            this.saveProgress = true;
            this.modal.hide();
            // eslint-disable-next-line no-undef
            emitter.emit('open-add-new-content-calendar-modal');
        },
        toggleSelection(event, aiAssistantOutput) {
            // Remove 'selected' class from all items
            this.$refs.outputItems.forEach((item) => {
                item.classList.remove('selected');
            });

            // Add 'selected' class to the clicked item
            event.currentTarget.classList.add('selected');

            // Call the store method
            this.addAssitantOutputToStore(aiAssistantOutput);
        },
        addAssitantOutputToStore(aiAssistantOutput) {
            this.isOutputSelected = true;
            this.contentCalendar = {
                ...this.contentCalendar,
                ...aiAssistantOutput,
                ...{ status: 'pending' },
            };
        },
        add() {
            this.saveProgress = true;
            this.modal.hide();
            // eslint-disable-next-line no-undef
            emitter.emit('open-content-calendar-content-preview-modal');
        },
    },
});
</script>

<style lang="scss">
#kt_modal_output_selection {
    .modal-dialog {
        .modal-header {
            z-index: 1;
        }

        .modal-body {
            .content-calendar-output-selection {
                max-height: 400px;
                overflow-y: auto;
            }
        }

        .content-calendar-output-selection-item {
            border-radius: 12px;
            border: 1px solid #e4e6ef;
            background-color: #f6f8fa;
            padding: 12px;
            transition: all 0.3s ease;
            cursor: pointer;

            &:hover,
            &.selected {
                background-color: #f1faff;
                border-color: #00a3ff;
            }
        }

        .modal-action {
            input,
            select {
                width: 100%;
                height: 50px;
                border-radius: 12px;
                border: 1px solid #e4e6ef;
                background-color: #f6f8fa;
                font-size: 16px;
                color: #101d36;
                padding: 12px;

                &:focus,
                &:active {
                    border-color: #e4e6ef;
                    outline: none;
                }
            }

            input {
                padding-left: 35px;
            }

            .search-wrapper {
                i {
                    font-size: 16px;
                    color: #101d36;
                    left: 12px;
                }
            }

            input::placeholder {
                color: #101d36;
            }
        }
    }
}
</style>

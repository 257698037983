<template>
    <div class="aside-menu flex-column-fluid">
        <!--begin::Aside Menu-->
        <div
            class="my-5"
            style="height: 87vh"
            id="kt_aside_menu_wrapper"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            data-kt-scroll-wrappers="#kt_aside_menu"
            data-kt-scroll-offset="0"
        >
            <!--begin::Menu-->
            <div
                class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                id="#kt_aside_menu"
                data-kt-menu="true"
                data-kt-menu-expand="false"
            >
                <!-- <div class="menu-item">
                    <div class="menu-content pt-8 pb-2">
                        <span
                            class="menu-section text-muted text-uppercase fs-8 ls-1"
                            >{{ __('Switch team or project') }}</span
                        >
                    </div>
                </div> -->

               <TeamSelection :isMenuCollapsed="isMenuCollapsed" :is-menu-hover="isMenuHover" />

                <div
                    data-kt-menu-trigger="click"
                    class="menu-item menu-accordion pt-4"
                >
                    <span class="menu-link">
                        <span class="menu-icon">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                            <span class="svg-icon svg-icon-1">
                                <IconDashboard />
                            </span>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="menu-title">
                            <Link :href="route('dashboard')" class="d-inline cursor-pointer">
                                {{ __('Dashboard') }}
                            </Link>
                        </span>
                    </span>
                </div>

                <div
                    data-kt-menu-trigger="click"
                    class="menu-item menu-accordion"
                >
                    <span class="menu-link">
                        <span class="menu-icon">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                            <span class="svg-icon svg-icon-1">
                                <IconCalendar />
                            </span>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="menu-title d-flex align-items-center">
                            <Link :href="route('content-calendar.index')" class="d-inline cursor-pointer">
                                {{ __('Content Planner') }}
                            </Link>
                            <span class="new-badge badge badge-primary align-self-top ms-1">{{ __('New') }}</span>
                        </span>
                    </span>
                </div>

                <div
                    class="menu-item show menu-accordion"
                    @click="toggle($event)"
                >
                    <span class="menu-link">
                        <span class="menu-icon">
                            <!--begin::Svg Icon | path: assets/media/icons/duotune/general/gen055.svg-->
                            <span class="svg-icon svg-icon-1">
                                <IconRocket />
                            </span>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="menu-title">{{ __('SEO Suite') }}</span>
                        <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion">
                        <!--begin:Menu item-->
                        <div class="menu-item">
                            <!--begin:Menu link-->
                            <Link :href="route('serp-beater.list')"
                                class="menu-link"
                            >
<!--                                <span class="menu-bullet">-->
<!--                                    <span-->
<!--                                        class="bullet bullet-dot"-->
<!--                                    ></span>-->
<!--                                </span>-->
                                <span class="menu-title">{{
                                    __('SERP Beater')
                                }}</span>
                            </Link>
                        </div>
                        <div class="menu-item">
                            <!--begin:Menu link-->
                            <Link href="/keyword-research/list" class="menu-link">
<!--                                <span class="menu-bullet">-->
<!--                                    <span-->
<!--                                        class="bullet bullet-dot"-->
<!--                                    ></span>-->
<!--                                </span>-->
                                <span class="menu-title">{{
                                    __('Keyword Research')
                                }}</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div
                    class="menu-item show menu-accordion"
                    @click="toggle($event)"
                >
                    <span class="menu-link">
                        <span class="menu-icon">
                            <span class="svg-icon svg-icon-primary svg-icon-1">
                                <IconMagicEdit />
                            </span>
                        </span>
                        <span class="menu-title">{{ __('AI Tools') }}</span>
                        <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion">
                        <div class="menu-item">
                            <Link :href="route('wizard')" class="menu-link">
<!--                                <span class="menu-bullet">-->
<!--                                    <span-->
<!--                                        class="bullet bullet-dot"-->
<!--                                    ></span>-->
<!--                                </span>-->
                                <span class="menu-title">{{
                                    __('Article Wizard')
                                }}</span>
                            </Link>
                        </div>
                        <div class="menu-item">
                            <Link :href="route('ai-assistant.selection')"
                                class="menu-link"
                            >
<!--                                <span class="menu-bullet">-->
<!--                                    <span-->
<!--                                        class="bullet bullet-dot"-->
<!--                                    ></span>-->
<!--                                </span>-->
                                <span class="menu-title">{{
                                    __('AI Assistant')
                                }}</span>
                            </Link>
                        </div>
                        <div class="menu-item">
                            <Link :href="
                                    route('image-generator-collections.list')
                                "
                                class="menu-link"
                            >
<!--                                <span class="menu-bullet">-->
<!--                                    <span-->
<!--                                        class="bullet bullet-dot"-->
<!--                                    ></span>-->
<!--                                </span>-->
                                <span class="menu-title">{{
                                    __('Image Generator')
                                }}</span>
                            </Link>
                        </div>
                        <!--
                        <div class="menu-item">
                            <Link :href="route('creation.open-form')"
                                class="menu-link"
                            >
                                <span class="menu-bullet">
                                    <span>
                                        class="bullet bullet-dot"
                                    ></span>
                                </span>
                                <span class="menu-title">{{
                                    __('Open Form')
                                }}</span>
                            </Link>
                        </div>-->
                        <div class="menu-item">
                            <Link :href="route('chat-gpt')" class="menu-link">
<!--                                <span class="menu-bullet">-->
<!--                                    <span-->
<!--                                        class="bullet bullet-dot"-->
<!--                                    ></span>-->
<!--                                </span>-->
                                <span class="menu-title">{{
                                    __('AI Chat')
                                }}</span>
                            </Link>
                        </div>
                        <div class="menu-item">
                            <!--begin:Menu link-->
                            <Link :href="route('bulk-processings.index')" class="menu-link">
<!--                                <span class="menu-bullet">-->
<!--                                    <span-->
<!--                                        class="bullet bullet-dot"-->
<!--                                    ></span>-->
<!--                                </span>-->
                                <span class="menu-title">{{
                                    __('Bulk processing')
                                }}</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div
                    class="menu-item show menu-accordion"
                    @click="toggle($event)"
                >
                    <span class="menu-link">
                        <span class="menu-icon">
                            <span class="svg-icon svg-icon-1">
                                <IconDocument />
                            </span>
                        </span>
                        <span class="menu-title">{{ __('Documents') }}</span>
                        <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion">
                        <div class="menu-item">
                            <!--begin:Menu link-->
                            <Link :href="route('document.new')" class="menu-link">
<!--                                <span class="menu-bullet">-->
<!--                                    <span-->
<!--                                        class="bullet bullet-dot"-->
<!--                                    ></span>-->
<!--                                </span>-->
                                <span class="menu-title">{{
                                    __('Editor')
                                }}</span>
                            </Link>
                        </div>
                        <div class="menu-item">
                            <!--begin:Menu link-->
                            <Link :href="route('documents.list')"
                                class="menu-link"
                            >
<!--                                <span class="menu-bullet">-->
<!--                                    <span-->
<!--                                        class="bullet bullet-dot"-->
<!--                                    ></span>-->
<!--                                </span>-->
                                <span class="menu-title">{{
                                    __('My Documents')
                                }}</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div
                    class="menu-item show menu-accordion"
                    @click="toggle($event)"
                >
                    <span class="menu-link">
                        <span class="menu-icon">
                            <span class="svg-icon svg-icon-1">
                                <IconMisc />
                            </span>
                        </span>
                        <span class="menu-title">{{ __('Misc') }}</span>
                        <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion">
                        <div class="menu-item">
                            <!--begin:Menu link-->
                            <Link :href="route('creation.output')"
                                class="menu-link"
                            >
<!--                                <span class="menu-bullet">-->
<!--                                    <span-->
<!--                                        class="bullet bullet-dot"-->
<!--                                    ></span>-->
<!--                                </span>-->
                                <span class="menu-title">{{
                                    __('Output')
                                }}</span>
                            </Link>
                        </div>
                        <div class="menu-item">
                            <!--begin:Menu link-->
                            <Link :href="route('translate')" class="menu-link">
<!--                                <span class="menu-bullet">-->
<!--                                    <span-->
<!--                                        class="bullet bullet-dot"-->
<!--                                    ></span>-->
<!--                                </span>-->
                                <span class="menu-title">{{
                                    __('Translate')
                                }}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin::Trigger-->
        <!--end::Aside Menu-->
    </div>
</template>

<script>
import { Link } from '@inertiajs/vue3';
import { directive } from 'vue3-click-away';
import TeamSelection from '@/Layouts/Aside/TeamSelection.vue';
import IconDashboard from '@/icons/dashboard.svg?component';
import IconRocket from '@/icons/rocket.svg?component';
import IconMagicEdit from '@/icons/magic-edit.svg?component';
import IconDocument from '@/icons/document.svg?component';
import IconCalendar from '@/icons/calendar.svg?component';
import IconMisc from '@/icons/misc.svg?component';

export default {
    components: {
        Link,
        TeamSelection,
        IconDashboard,
        IconRocket,
        IconMagicEdit,
        IconDocument,
        IconCalendar,
        IconMisc,
    },

    directives: {
        ClickAway: directive,
    },

    props: {
        user: Object,
        isMenuCollapsed: Boolean,
        isMenuHover: Boolean,
    },

    mounted() {
        console.log(this.route('dashboard'));
        let menuItems = document.getElementsByClassName('menu-link');
        Array.from(menuItems).forEach(function (menuItem) {
            menuItem.addEventListener('click', function () {
                emitter.emit('close-aside-mobile-menu');
            });
        });

        let menuTrigger = document.querySelectorAll(
            '.menu-accordion[data-kt-menu-trigger=click]'
        );
        menuTrigger.forEach((item) => {
            item.addEventListener('click', function () {
                item.classList.toggle('hover');
                item.classList.toggle('show');
            });
        });
    },

    methods: {
        toggle($event) {
            let s = $event.target.closest('.menu-item').classList;
            s.contains('show') ? s.remove('show') : s.add('show');
        },
        onClickAway(event) {
            this.toggle(event);
        },
    },
    computed: {
        currentProjectName() {
            return this.$page.props.user.current_project
                ? this.$page.props.user.current_project.name
                : '';
        },
        planBasedLtd() {
            return (
                this.$page.props.user.current_team.lifetime_subscriptions.filter(
                    (subscription) => {
                        return subscription.plan;
                    }
                ).length > 0
            );
        },
        planBasedLtdStandard() {
            return (
                this.$page.props.user.current_team.lifetime_subscriptions.filter(
                    (subscription) => {
                        return subscription.plan === 'standard';
                    }
                ).length > 0
            );
        },
        planBasedLtdProfessional() {
            return (
                this.$page.props.user.current_team.lifetime_subscriptions.filter(
                    (subscription) => {
                        return subscription.plan === 'professional';
                    }
                ).length > 0
            );
        },
        userIsOnTrial() {
            return this.$page.props.user.on_trial;
        },
        hasAccessToSerpBeater() {
            return (
                this.userIsOnTrial ||
                this.hasProfessionalPlan ||
                this.$page.props.user.current_team.lifetime_subscriptions
                    .length > 1
            );
        },
        hasAccessToKeywordResearch() {
            return (
                this.userIsOnTrial ||
                this.hasProfessionalPlan ||
                this.$page.props.user.current_team.lifetime_subscriptions
                    .length > 1
            );
        },
        hasAccessToTranslationForm() {
            return (
                this.userIsOnTrial ||
                this.hasStandardPlan ||
                this.hasProfessionalPlan ||
                this.$page.props.user.current_team.lifetime_subscriptions
                    .length > 0
            );
        },
        hasStandardPlan() {
            return (
                (this.user.current_team.subscriptions.length > 0
                    ? this.getPlanIds('standard').includes(
                          this.user.current_team.subscriptions[0].paddle_plan.toString()
                      )
                    : false) || this.planBasedLtdStandard
            );
        },
        hasProfessionalPlan() {
            return (
                (this.user.current_team.subscriptions.length > 0
                    ? this.getPlanIds('professional').includes(
                          this.$page.props.user.current_team.subscriptions[0].paddle_plan.toString()
                      )
                    : false) || this.planBasedLtdProfessional
            );
        },
        hasAddons() {
            return this.user.current_team.subscriptions.length > 0
                ? this.user.current_team.subscriptions[0].addons.length > 0
                : false;
        },
    },
};
</script>

<style lang="scss">
.new-badge {
    font-size: 0.5rem!important;
}
</style>

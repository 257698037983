<template>
    <div
        class="modal fade"
        id="kt_modal_content_preview"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered modal-xl w-[760px]">
            <!--begin::Modal content-->
            <div class="modal-content p-5">
                <!--begin::Modal header-->
                <div class="modal-header bg-white p-0 pb-5 mb-5">
                    <!--begin::Heading-->
                    <div>
                        <h2>{{ __('Add to content planner') }}</h2>
                        <div>
                            <strong>{{ __('Step') }}</strong> 1/2
                        </div>
                    </div>
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body p-0 m-0">
                    <div>
                        <div
                            class="add-to-content-calendar-preview-content overflow-hidden"
                        >
                            <div
                                class="add-to-content-calendar-preview-content-heading d-flex justify-content-between"
                            >
                                <h5 class="mb-0">
                                    {{
                                        getSocialMediaName(
                                            contentCalendar.social_media
                                        )
                                    }}
                                </h5>
                                <button
                                    class="border-0 bg-transparent flex items-center gap-2"
                                    @click="copyContent"
                                >
                                    <span
                                        class="svg-icon svg-icon-3 text-dark"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"
                                            />
                                        </svg>
                                    </span>
                                    <strong>
                                        {{ __('Copy content') }}
                                    </strong>
                                </button>
                            </div>
                            <div
                                class="add-to-content-calendar-preview-content-body max-h-[65vh] overflow-auto"
                            >
                                <p
                                    v-html="
                                        parsedContent(
                                            contentCalendar?.content || ''
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div
                            class="add-to-content-calendar-preview-content-footer d-flex justify-content-between border-top pt-5 mt-5"
                        >
                            <button
                                class="btn btn-link me-3 d-inline-flex align-items-center"
                                @click="openEditModal"
                            >
                                <i class="bi bi-pencil-square"></i>
                                {{ __('Edit') }}
                            </button>
                            <div class="flex gap-2">
                                <button
                                    class="btn btn-secondary me-3"
                                    @click="closeModal"
                                >
                                    {{ __('Cancel') }}
                                </button>
                                <button class="btn btn-primary" @click="next">
                                    {{ __('Next') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';
import { useContentCalendarStore } from '@/Stores/ContentCalendarStore';
import { mapState, mapWritableState } from 'pinia';
import { useToast } from 'vue-toastification';
import MarkdownIt from 'markdown-it';

export default defineComponent({
    setup() {
        const contentCalendarStore = useContentCalendarStore();
        const toast = useToast();

        return {
            contentCalendarStore,
            toast,
        };
    },
    data() {
        return {
            modal: {},
            saveProgress: false,
            markdownIt: new MarkdownIt(),
        };
    },
    mounted() {
        let vm = this;

        this.modal = new Modal(
            document.getElementById('kt_modal_content_preview')
        );

        // Add event listener for modal hidden event
        document
            .getElementById('kt_modal_content_preview')
            .addEventListener('hidden.bs.modal', this.handleModalHidden);

        // eslint-disable-next-line no-undef
        emitter.on('open-content-calendar-content-preview-modal', () =>
            vm.modal.show()
        );
        // eslint-disable-next-line no-undef
        emitter.on('close-content-calendar-content-preview-modal', () =>
            vm.modal.hide()
        );
    },
    beforeUnmount() {
        // Remove the event listener when component is unmounted
        document
            .getElementById('kt_modal_content_preview')
            .removeEventListener('hidden.bs.modal', this.handleModalHidden);

        // eslint-disable-next-line no-undef
        emitter.off('open-content-calendar-content-preview-modal');
        // eslint-disable-next-line no-undef
        emitter.off('close-content-calendar-content-preview-modal');
    },
    computed: {
        ...mapState(useContentCalendarStore, ['getSocialMediaName']),
        ...mapWritableState(useContentCalendarStore, ['contentCalendar']),
    },
    methods: {
        handleModalHidden() {
            // This method will be called whenever the modal is hidden
            if (!this.saveProgress) {
                this.contentCalendarStore.$reset();
            }
            // Reset the flag
            this.saveProgress = false;
        },
        closeModal() {
            this.saveProgress = true;
            this.modal.hide();
            // eslint-disable-next-line no-undef
            emitter.emit('open-content-calendar-output-selection-modal');
        },
        openEditModal() {
            this.saveProgress = true;
            this.modal.hide();
            // eslint-disable-next-line no-undef
            emitter.emit('open-content-calendar-content-edit-modal');
        },
        next() {
            this.saveProgress = true;
            this.modal.hide();
            // eslint-disable-next-line no-undef
            emitter.emit('open-content-calendar-time-selection-modal');
        },
        copyContent() {
            const text = this.contentCalendar?.content;

            // Create a temporary element to hold the HTML content
            let tempElement = document.createElement('div');
            tempElement.innerHTML = text;
            document.body.appendChild(tempElement);

            // Select the content in the temporary element
            let range = document.createRange();
            range.selectNodeContents(tempElement);
            let selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);

            // Copy the selected content to the clipboard
            try {
                document.execCommand('copy');
                this.toast.success(this.__('Content copied to clipboard'));
            } catch (err) {
                this.toast.error(this.__('Failed to copy content'));
            }

            // Clean up by removing the temporary element and clearing the selection
            document.body.removeChild(tempElement);
            selection.removeAllRanges();
        },
        parsedContent(content) {
            // First convert line breaks to <br> tags before markdown processing
            const textWithBreaks = content.replace(/\n/g, '\n\n');
            return this.markdownIt.render(textWithBreaks);
        },
    },
});
</script>

<style lang="scss">
#kt_modal_content_preview {
    .modal-dialog {
        .modal-header {
            z-index: 1;
        }

        .modal-body {
            .add-to-content-calendar-preview-content-heading {
                padding: 15px 20px;
                background-color: #fafbfb;
            }

            .add-to-content-calendar-preview-content {
                border-radius: 12px;
                border: 1px solid #e4e6ef;
            }

            .add-to-content-calendar-preview-content-body {
                padding: 20px;

                p {
                    margin-bottom: 0.5rem;
                }
            }
        }

        .add-to-content-calendar-preview-content-footer {
            .btn-link {
                color: #019ef7;
                font-size: 14px;
                font-weight: 600;

                i {
                    font-size: 14px;
                    color: inherit;
                }

                &:hover {
                    color: #181c32;
                }
            }
        }
    }
}
</style>
